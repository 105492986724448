<template>
    <div>
        <button @click="Cancel()" class="btn btn-link redirect-home">
            <b-img width="11" height="9" :src="closeFilterIcon" /> Back to requests</button>
        <div class="card direct-component">
            <h4 class="card-main-title" v-if="IsEdit&&isDraft&&isTemplate!=true">Edit &amp; Resubmit Wire Transfer Request</h4>
            <h4 class="card-main-title" v-else>New Wire Transfer Request</h4>

            <div class="transaction-number" v-if="IsEdit&&isDraft&&isTemplate!=true">
                <p class="transaction-label">Transaction ID Number</p>
                <p class="transaction-id">{{WireTransfer.ID}}</p>
            </div>
            <div class="section-title">Company and Vendor Info</div>
            <div class="section-desc">
                <p>Please populate all fields</p>
                <p></p>
            </div>
            <div class="section-content">
                <div class="section-row row">
                    <div class="col-lg-2">
                        <b-form-group class="custom-radio" label="">
                            <slot name="label">
                                <legend class="col-form-label">Is This a new vendor?
                                    <custom-tooltip :content="'Select Yes or No for \'Is this a new vendor?\' (Note: for new vendors, you will need to proivde the Vendor\'s contact info in the section below).'"></custom-tooltip>
                                </legend>
                            </slot>
                            <b-form-radio-group id="Is-new-vendor" v-model="WireTransfer.NewVendor">
                                <b-form-radio value="Yes">Yes</b-form-radio>
                                <b-form-radio value="No">No</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                    </div>
                    <div class="col-lg-3">
                        <slot name="label">
                            <legend class="col-form-label">Payment Type
                                <custom-tooltip :content="'Select Payment Type for the drop down'"></custom-tooltip>
                            </legend>
                        </slot>
                        <OmniSelect :tabindex="0" v-model="WireTransfer.PaymentType" :options="PaymentTypeOptions" placeholder="Select Payment Type" />
                    </div>
                    <div class="col-lg-3">
                        <slot name="label">
                            <legend class="col-form-label">Company Name
                                <custom-tooltip :content="'Select Company making the payment from the drop down'"></custom-tooltip>
                            </legend>
                        </slot>
                        <OmniSelect :tabindex="0" v-model="WireTransfer.CompanyName" :options="CompanyNameOptions" placeholder="Select Company Name" />
                    </div>
                </div>
                <div class="section-row row">
                    <div class="col-lg-2">
                        <slot name="label">
                            <legend class="col-form-label">Select a system
                                <custom-tooltip :content="'Select a System.  Note: this will default based on the payment type'"></custom-tooltip>
                            </legend>
                        </slot>
                        <b-form-group class="custom-radio">
                            <b-form-radio-group id="vendor-system" v-model="WireTransfer.VendorSystem">
                                <b-form-radio value="AX" v-if="!hasPayrollPermission">AX</b-form-radio>
                                <b-form-radio value="DDS" v-if="!hasPayrollPermission">DDS</b-form-radio>
                                <b-form-radio v-if="hasPayrollPermission" value="Payroll">Payroll</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                    </div>
                    <div class="col-lg-3" v-if="WireTransfer.VendorSystem=='DDS'">
                        <OmniSelect :tabindex="0" name="Media Type" :toolTipText="'For Media and Vendor Refund Payment Types provide the media type'" v-model="WireTransfer.MediaType" :options="MediaList" placeholder="Select Media Type" />
                    </div>
                    <div class="col-lg-3">

                        <!-- <OmniSelect :toolTipText="'test'" v-model="WireTransfer.VendorCode" :options="VendorCodeOptions" :name="VendorCodelabel" :placeholder="VendorCodePlaceholder">

                        </OmniSelect> -->

                        <b-form-group v-if="IsVendorCodeVisible">
                            <slot name="label">
                                <legend class="col-form-label">{{VendorCodelabel}}
                                    <custom-tooltip :content="'Enter the Vendor Code'"></custom-tooltip>
                                </legend>
                            </slot>
                            <b-form-input autocomplete="off" v-model="WireTransfer.VendorCode" :placeholder="'Enter '+VendorCodelabel "></b-form-input>
                        </b-form-group>
                    </div>
                </div>
                <div class="section-devider"></div>
            </div>
            <div class="section-title"> Wire Transfer Info</div>
            <div class="section-desc">Please populate all fields</div>
            <div class="section-content">
                <div class="section-row row">
                    <div class="col-lg-3">
                        <slot name="label">
                            <legend class="col-form-label">Select a Transfer Type:
                                <custom-tooltip :content="'Select Domestic or International based on the country the vendor is located in'"></custom-tooltip>
                            </legend>
                        </slot>
                        <b-form-group class="custom-radio">
                            <b-form-radio-group id="transfertype" v-model="WireTransfer.TransferType">
                                <b-form-radio value="Domestic">Domestic</b-form-radio>
                                <b-form-radio value="International">International</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                    </div>
                    <div class="col-lg-3">
                        <OmniSelect :tabindex="0" name="Debit Company" :toolTipText="'Debit Company will populate based on the Company Name selected above (this is the company making the payment)'" v-model="WireTransfer.DebitCompany" :options="DebitCompanyOptionsMapping" placeholder="Select Debit Company" />
                    </div>
                    <div class="col-lg-3" v-if="IsEdit && isTemplate!=true">
                        <OmniSelect :showLabel="true" name="Debit Account Number" v-model="WireTransfer.DebitAccountNumber" :options="DebitAccountNumberOptions" placeholder="Select Account Number" />
                    </div>
                </div>
                <div class="section-row row">
                    <div class="col-lg-3">
                        <b-form-group>
                            <slot name="label">
                                <legend class="col-form-label">Beneficiary
                                    <custom-tooltip :content="'Vendor Name'"></custom-tooltip>
                                </legend>
                            </slot>
                            <b-form-input id="beneficiary" autocomplete="off" v-model="WireTransfer.Beneficiary" placeholder="Enter Beneficiary"></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-lg-3">
                        <b-form-group>
                            <slot name="label">
                                <legend class="col-form-label">Beneficiary Street Address
                                    <custom-tooltip :content="'Vendor Street Address'"></custom-tooltip>
                                </legend>
                            </slot>
                            <b-form-input id="beneficiarystreetaddress" name="Beneficiary Street Address" autocomplete="off" v-model="WireTransfer.BeneficiaryStreetAddress" placeholder="Enter Beneficiary Address"></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-lg-3">
                        <OmniSelect :tabindex="0" :toolTipText="'Vendor Country'" name="Beneficiary Country" v-model="WireTransfer.BeneficiaryCountry" :options="CountryOptions" placeholder="Select Beneficiary Country" />
                         <!-- <OmniCustomSelect :selectOnKeyCodes="OnSelectKeyCodes" v-model="WireTransfer.BeneficiaryCountry" :options="CountryOptions" name="Beneficiary Country" placeholder="Select Beneficiary Country" /> -->

                    </div>
                    <div class="col-lg-3">
                        <slot name="label">
                            <legend class="col-form-label">Beneficiary City
                                <custom-tooltip :content="'Vendor City'"></custom-tooltip>
                            </legend>
                        </slot>
                        <b-form-group>
                            <b-form-input autocomplete="off" id="BeneficiaryCity" v-model="WireTransfer.BeneficiaryCity" placeholder="Enter City"></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-lg-6">
                        <div class="row">
                            <div class="col-lg-6">
                                <slot name="label">
                                    <legend class="col-form-label">Beneficiary Post Code
                                        <custom-tooltip :content="'Zip or Postal Code'"></custom-tooltip>
                                    </legend>
                                </slot>
                                <b-form-group>
                                    <b-form-input autocomplete="off" id="BeneficiaryZipCode" v-model="WireTransfer.BeneficiaryZipCode" placeholder="Enter Zip Code / Post Code"></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-lg-6">
                                <slot name="label">
                                    <legend class="col-form-label">Beneficiary Reference
                                        <custom-tooltip :content="'Invoice # or Reference'"></custom-tooltip>
                                    </legend>
                                </slot>
                                <b-form-group>
                                    <b-form-input title="Enter Reference Number / Invoice Number" autocomplete="off" id="BeneficiaryReference" v-model="WireTransfer.BeneficiaryReference" placeholder="Enter Reference Number / Invoice Number"></b-form-input>
                                </b-form-group>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="section-row row">
                    <div class="col-lg-3">
                        <slot name="label">
                                <legend class="col-form-label">Credit Account Name
                                    <custom-tooltip :content="'Name on Vendor\'s Bank Account'"></custom-tooltip>
                                </legend>
                            </slot>
                        <b-form-group>
                            <b-form-input autocomplete="off" id="CreditAccountName" v-model="WireTransfer.CreditAccountName" placeholder="Enter Credit Account Name"></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-lg-3">
                        <b-form-group>
                            <slot name="label">
                                <legend class="col-form-label">Credit Account Number
                                    <custom-tooltip :content="'Vendor\'s Bank Account Number (If IBAN is provided, it should be entered here as the account number)'"></custom-tooltip>
                                </legend>
                            </slot>
                            <b-form-input autocomplete="off" id="CreditAccountNumber" v-model="WireTransfer.CreditAccountNumber" placeholder="Enter Credit Account Number or IBAN (if applicable)"></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-lg-3">
                        <OmniSelect :tabindex="0" :toolTipText="'Select from the drop down the currency stated on the invoice'" name="Currency" v-model="WireTransfer.Currency" :options="CurrencyOptions" placeholder="Select Currency" />
                    </div>
                </div>
                <div class="section-row row">
                    <div class="col-lg-3">
                        <slot name="label">
                            <legend class="col-form-label">Bank Name
                                <custom-tooltip :content="'Name of Vendor\'s Bank'"></custom-tooltip>
                            </legend>
                        </slot>
                        <b-form-group>
                            <b-form-input autocomplete="off" id="BankName" v-model="WireTransfer.BankName" placeholder="Enter Bank Name"></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-lg-3">
                        <OmniSelect :tabindex="0" :toolTipText="'Country Vendor\'s Bank is located in'" name="Bank Country" v-model="WireTransfer.BankCountry" :options="CountryOptions" placeholder="Select Country" />
                    </div>
                    <div class="col-lg-6">
                        <div class="row">
                            <div class="col-lg-4">
                                <OmniSelect :tabindex="0" :toolTipText="'Select Bank Identifier Type from the dropdown (Fedwire = ABA / Routing Number, Swift should be select if you are paying via Swift or IBAN)'" name="BIC Type" v-model="WireTransfer.BankBICType" :options="BankBICTypeOptions" placeholder="Select BIC Type" />
                            </div>
                            <div class="col-lg-4">
                                <b-form-group>
                                    <slot name="label">
                                        <legend class="col-form-label">BIC
                                            <custom-tooltip :content="'Enter Bank Identifier Number'"></custom-tooltip>
                                        </legend>

                                    </slot>
                                    <b-form-input autocomplete="off" id="BankBIC" v-model="WireTransfer.BankBIC" placeholder="Enter BIC"></b-form-input>
                                </b-form-group>
                            </div>

                        </div>

                    </div>
                </div>
                <div class="section-row row">
                    <div class="col-lg-3">
                        <OmniSelect :tabindex="0" v-model="WireTransfer.InvoiceCurrency" :toolTipText="'Currency to be paid'" :options="InvoiceCurrencyOptions" name="Invoice Currency" placeholder="Select Currency" />

                    </div>
                    <div class="col-lg-3">
                        <b-form-group>
                            <slot name="label">
                                <legend class="col-form-label">Total Invoice Amount
                                    <custom-tooltip :content="'Total amount to be paid'"></custom-tooltip>
                                </legend>

                            </slot>
                            <b-form-input @blur="formatCurrency" @focus="resetFormat" v-numericWithDecimal title="Total Invoice Amount" autocomplete="off" id="TotalInvoiceAmount" v-model="WireTransfer.TotalInvoiceAmount" placeholder="Enter Total Amount"></b-form-input>
                            <div v-show="this.$v.WireTransfer.TotalInvoiceNumberAmount.$invalid && this.$v.WireTransfer.TotalInvoiceNumberAmount.$dirty">
                                <p v-show="!this.$v.WireTransfer.TotalInvoiceNumberAmount.max" class="error-message-format">
                                    {{this.$v.WireTransfer.TotalInvoiceNumberAmount.max.$message}} Amount should not exceed 25,000,000
                                </p>
                            </div>
                        </b-form-group>
                    </div>
                </div>
                <div class="section-row row">
                    <div class="col-lg-3">
                        <!-- <b-form-group>
                            <slot name="label">
                                <legend class="col-form-label">Requested Transfer Date
                                    <custom-tooltip :content="'test'"></custom-tooltip>
                                </legend>

                            </slot>
                            <b-form-input class="form-control" type="date" v-model="WireTransfer.RequestedTransferDate" autocomplete="off"></b-form-input>
                        </b-form-group> -->
                        <!-- <floating-label-input
              v-model="WireTransfer.RequestedTransferDate"
              label="Requested Transfer Date"
              inputType="date"
              :first-day-of-week="1"
              :readonly="false"
              allowClear
            /> -->
                        <calendar-picker  customDateFormat="MM/DD/YYYY" label="Requested Transfer Date" v-if="isDataLoaded" inputType="date" id="calendar-picker" v-model="RequestedTransferDate">

                        </calendar-picker>
                        <!-- <floating-label-input v-model="WireTransfer.RequestedTransferDate" label="Requested Transfer Date" inputType="date" :first-day-of-week="1" :readonly="false" allowClear /> -->
                    </div>
                    <div class="col-lg-3">
                        <b-form-group>
                            <slot name="label">
                                <legend class="col-form-label">Paying Bank Charges
                                    <custom-tooltip :content="'Should always be \'Remitter\''"></custom-tooltip>
                                </legend>

                            </slot>
                            <b-form-input autocomplete="off" id="PayingBankCharges" v-model="WireTransfer.PayingBankCharges" placeholder="Enter Paying Bank Charges"></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-lg-3">
                        <b-form-group>
                            <slot name="label">
                                <legend class="col-form-label">Receiving Bank Charges
                                    <custom-tooltip :content="'Should always be \'Remitter\''"></custom-tooltip>
                                </legend>

                            </slot>
                            <b-form-input autocomplete="off" id="ReceivingBankCharges" v-model="WireTransfer.ReceivingBankCharges" placeholder="Enter Receiving Bank Charges"></b-form-input>

                        </b-form-group>
                    </div>
                    <div class="col-lg-2">
                        <b-form-group class="custom-radio">
                            <slot name="label">
                                <legend class="col-form-label">Remittance Advice
                                    <custom-tooltip :content="'Should always be \'Yes\''"></custom-tooltip>
                                </legend>

                            </slot>
                            <b-form-radio-group id="remittance-advice" v-model="WireTransfer.RemittanceAdvice">
                                <b-form-radio value="Yes">Yes</b-form-radio>
                                <b-form-radio value="No">No</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                    </div>
                </div>
                <div class="section-devider"></div>
            </div>
            <div class="section-title" v-if="IsVendorInfoRequired">Vendor Contact Info</div>
            <div class="section-desc" v-if="IsVendorInfoRequired">This section will only appear if "Yes" is selected to the "Is this a new vendor?" question at the</div>
            <div class="section-content" v-if="IsVendorInfoRequired">
                <div class="section-row row" v-if="IsVendorInfoRequired">
                    <div class="col-lg-2">
                        <slot name="label">
                            <legend class="col-form-label">Vendor Contact Name
                                <custom-tooltip :content="'Enter name of the vendor contact'"></custom-tooltip>
                            </legend>
                        </slot>
                        <b-form-group>
                            <b-form-input autocomplete="off" v-model="WireTransfer.VendorName" placeholder="Enter Name"></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-lg-2">
                        <slot name="label">
                            <legend class="col-form-label">Vendor Contact Email
                                <custom-tooltip :content="'Enter email address of the vendor contact'"></custom-tooltip>
                            </legend>
                        </slot>
                        <b-form-group>
                            <b-form-input autocomplete="off" @blur="SetDirty('VendorEmail')" @focus="ResetDirty('VendorEmail')" v-model="WireTransfer.VendorEmail" placeholder="Enter Email"></b-form-input>
                            <div v-show="this.$v.WireTransfer.VendorEmail.$invalid && this.$v.WireTransfer.VendorEmail.$dirty">
                                <p v-show="!this.$v.WireTransfer.VendorEmail.email" class="error-message-format">
                                    Enter a valid email address
                                </p>
                            </div>
                        </b-form-group>
                    </div>
                    <div class="col-lg-2">
                        <slot name="label">
                            <legend class="col-form-label">Vendor Contact Phone
                                <custom-tooltip :content="'Vendor Contact Phone'"></custom-tooltip>
                            </legend>
                        </slot>
                        <b-form-group>
                            <b-form-input autocomplete="off" v-model="WireTransfer.VendorPhoneNumber" placeholder="Enter Phone Number"></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-lg-2">
                        <slot name="label">
                            <legend class="col-form-label">Vendor Contact  Role
                                <custom-tooltip :content="'Vendor Contact Role'"></custom-tooltip>
                            </legend>
                        </slot>
                        <b-form-group>
                            <b-form-input autocomplete="off" v-model="WireTransfer.VendorRole" placeholder="Enter Role"></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-lg-4">
                        <!-- <b-form-group class="custom-radio" label="How did you obtain this vendor contact info:">
                            <b-form-radio-group v-model="WireTransfer.VendorContactInfoObtainedFrom">
                                <b-form-radio value="Website">Website</b-form-radio>
                                <b-form-radio value="ReferredFromOMGEmployee">Referred from OMG Employee</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group> -->
                        <OmniSelect :tabindex="0" :toolTipText="'How did you obtain vendor contact'" v-model="WireTransfer.VendorContactInfoObtainedFrom" :options="VendorContactInfoObtainedFromOptions" name="How did you obtain this vendor contact info?" placeholder="Select Source" />

                    </div>
                    <div class="col-lg-2" v-if="WireTransfer.VendorContactInfoObtainedFrom=='Website'">
                        <b-form-group label="Website URL">
                            <b-form-input autocomplete="off" @blur="SetDirty('VendorInfoObtainedWebsite')" @focus="ResetDirty('VendorInfoObtainedWebsite')" v-model="WireTransfer.VendorInfoObtainedWebsite" placeholder="Enter Website URL"></b-form-input>
                            <div v-show="this.$v.WireTransfer.VendorInfoObtainedWebsite.$invalid && this.$v.WireTransfer.VendorInfoObtainedWebsite.$dirty">
                                <p v-show="!this.$v.WireTransfer.VendorInfoObtainedWebsite.url" class="error-message-format">
                                    Enter a valid url address
                                </p>
                            </div>
                        </b-form-group>
                    </div>
                    <div class="col-lg-2" v-if="WireTransfer.VendorContactInfoObtainedFrom=='ReferredFromOMGEmployee'">
                        <b-form-group label="OMG Employee Name">
                            <b-form-input autocomplete="off" v-model="WireTransfer.OMGEmpolyeeName" placeholder="Enter OMG Employee Name"></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-lg-2" v-if="WireTransfer.VendorContactInfoObtainedFrom=='ReferredFromOMGEmployee'">
                        <b-form-group label="OMG Employee Role">
                            <b-form-input autocomplete="off" v-model="WireTransfer.OMGEmpolyeeRole" placeholder="Enter Role"></b-form-input>
                        </b-form-group>
                    </div>
                </div>
                <div class="section-devider"></div>
            </div>
            <div class="section-title">Paythrough Info</div>
            <div class="section-desc">(complete if a paythrough/intermediary bank is noted on the invoice). Please populate all fields.</div>
            <div class="section-content">
                <div class="section-row row">
                    <div class="col-lg-2">
                        <b-form-group class="custom-radio">
                            <slot name="label">
                                <legend class="col-form-label">Paythrough
                                    <custom-tooltip :content="'Select Yes or No'"></custom-tooltip>
                                </legend>

                            </slot>
                            <b-form-radio-group id="paythrough" v-model="WireTransfer.Paythrough">
                                <b-form-radio value="Yes">Yes</b-form-radio>
                                <b-form-radio value="No">No</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                    </div>
                    <div class="col-lg-3">
                        <slot name="label">
                            <legend class="col-form-label">Paythrough Bank Name
                                <custom-tooltip :content="'Name of Paythrough/Intermediary Bank'"></custom-tooltip>
                            </legend>
                        </slot>
                        <b-form-group :disabled="WireTransfer.Paythrough!='Yes'">
                            <b-form-input :disabled="WireTransfer.Paythrough!='Yes'" autocomplete="off" id="PaythroughBankName" v-model="WireTransfer.PaythroughBankName" placeholder="Enter Bank Name"></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-lg-3">
                        <OmniSelect :tabindex="0" :toolTipText="'Country Paythrough/Intermediary Bank is located in'" :disabled="WireTransfer.Paythrough!='Yes'" v-model="WireTransfer.PaythroughBankCountry" :options="CountryOptions" name="Paythrough Bank Country" placeholder="Select Country" />
                    </div>
                    <div class="col-lg-2">
                        <OmniSelect :tabindex="0" :toolTipText="'Select Bank Identifier Type from the dropdown (Fedwire = ABA / Routing Number, Swift should be select if you are paying Swift or IBAN'" :disabled="WireTransfer.Paythrough!='Yes'" v-model="WireTransfer.PaythroughBICType" :options="BankBICTypeOptions" name="Paythrough BIC Type" placeholder="Select Type" />
                    </div>
                    <div class="col-lg-2">
                        <slot name="label">
                            <legend class="col-form-label">Paythrough BIC
                                <custom-tooltip :content="'Enter Bank Identifier Number'"></custom-tooltip>
                            </legend>
                        </slot>
                        <b-form-group>
                            <b-form-input :disabled="WireTransfer.Paythrough!='Yes'" autocomplete="off" id="BankBIC" v-model="WireTransfer.PaythroughBIC" placeholder="Enter BIC"></b-form-input>
                        </b-form-group>
                    </div>
                </div>
                <div class="section-devider"></div>
            </div>
            <div class="section-title">Support Info</div>
            <div class="section-desc">REQUIRED - Please attach all support here (i.e. invoices, approvals, supporting documentation). Select "Watchers" from the drop down for any people you would like to cc. Miscellaneous notes can be provided in the Notes field.</div>
            <div class="section-content">
                <div class="section-row row">
                    <div class="col-lg-4">
                        <label>Attach Files</label>
                        <vue-dropzone :duplicateCheck="true" ref="myVueDropzone" id="dropzone" :useCustomSlot="true" class="customdropzone" :include-styling="false" :options="dropzoneOptions" v-on:vdropzone-file-added="UploadedToDropzone" v-on:vdropzone-removed-file="RemovedFromDropzone">
                            <div class="dropzone-custom-content">
                                <p class="Drag-and-drop-file-h">
                                    <b-img style="margin-top: -7px !important; font-family: 'Open Sans'" />
                                    Drag and drop file here or
                                    <span class="draganddropspan"> browse </span>
                                </p>
                            </div>
                        </vue-dropzone>
                    </div>
                    <!-- <div class="col-lg-3 stacked-rows">
                        <OmniSelect v-model="WireTransfer.AuthorizedBy" :options="AuthorizedOptions" name="Approved By" placeholder="Select Approver" />

                    </div> -->
                    <div class="col-lg-5" v-if="(IsEdit==true&&isDataLoaded==true) ||IsEdit!=true">
                        <omni-tag-input :toolTipText="'Watchers'" id="watchers" v-model="WireTransferRequestWatchers" :optns="Watchers" label="Watchers" placeholder="Select Watchers">

                        </omni-tag-input>
                    </div>
                    <!-- <div class="col-lg-4">
                        <label>Notes</label>
                        <b-textarea class="notes-fixed-height" placeholder="Provide Notes (optional)" label="Notes" :rows="5" v-model="WireTransfer.Notes">

                        </b-textarea>
                    </div> -->
                </div>
                <div class="section-row row">
                    <div class="col-lg-4">
                        <label>Notes</label>
                        <b-textarea class="notes-fixed-height" placeholder="Provide Notes (optional)" label="Notes" :rows="5" v-model="WireTransfer.Notes">

                        </b-textarea>
                    </div>
                    <div class="col-lg-8" v-if="!isSubmitOrSaveTemplate">
                        <label>Resubmit Notes</label>
                        <b-textarea :disabled="dataChanged!=true" class="notes-fixed-height" placeholder="Comments (required if any edits are made)" label="Notes" :rows="5" v-model="WireTransfer.ResubmitNotes">

                        </b-textarea>
                    </div>
                </div>
                <div class="d-flex justify-content-between">
        <div v-if="!isSubmitOrSaveTemplate" class="col-lg-5 horizontal-devider ">
            <b-form-group v-model="CreateAnotherRequest">
                <b-form-checkbox-group>
                    <b-form-checkbox v-model="CreateAnotherRequest" value="true">Create another request</b-form-checkbox>
                </b-form-checkbox-group>
            </b-form-group>
        </div>
        <div :class="IsEdit?'col-lg-12':'col-lg-7'">
            <button @click="Cancel()" class="btn custom-button btn-link">
                Cancel
            </button>
            <button @click="SaveAsDraft()" v-if="isSubmitOrSaveTemplate" class="text-uppercase btn btn-secondary btn-primary">
                SAVE FOR LATER
            </button>
            <button @click="SaveTemplate" v-if="isSubmitOrSaveTemplate" class="text-uppercase btn btn-secondary btn-primary">
                SAVE AS TEMPLATE
            </button>
            <button v-if="isSubmitOrSaveTemplate" :disabled="this.$v.$invalid" @click="Submit()" class="btn custom-button btn-primary">
                Submit Request
            </button>
            <button v-if="!isSubmitOrSaveTemplate && canResubmitRequest" :disabled="dataChanged==false||$v.$invalid" @click="Resubmit()" class="btn custom-button btn-primary">
                Resubmit
            </button>
        </div>
        <b-modal v-model="showTemplateModel" :no-close-on-backdrop="true"  size="xs"  hide-footer>
            <template #modal-header>
                <h7 class="modal-title">Save Template Data</h7>
                <button class="close" @click="closeDescriptionBox">X</button>
            </template>
            <b-form>
                <b-form-group label="Template Name">
                    <b-form-input v-model="TemplateName"></b-form-input>
                </b-form-group>
                <b-form-group label="Description">
                    <b-textarea v-model="Description" rows="2"></b-textarea>
                </b-form-group>
            </b-form>
            <div class="d-flex justify-content-end mt-2">
                <button @click="SaveAllTemplateData"  :disabled="!isSubmitDisabled" class="btn custom-button btn-primary">Submit</button>
                <button @click="closeDescriptionBox" class="btn custom-button btn-secondary ml-2">Close</button>
            </div>
        </b-modal>
            </div>
            </div>
        </div>
    </div>
    </template>

    <script>
    import store from "@/store";
    import vue2Dropzone from "vue2-dropzone";
    import "vue2-dropzone/dist/vue2Dropzone.min.css";
    import {
        requiredIf,
        required,
        minLength,
        maxValue,
        helpers
    } from "vuelidate/lib/validators";
    import Vuelidate from 'vuelidate'
    import moment from "moment";
    const currentDate = moment(new Date()).startOf('day')
    Vuelidate.prototype.$autoDirty = true;
    import service from "../../services/Dropdowns";
    import * as wiretransferStatus from "../../utilities/WireTransferStates";
    import {
        NETMedia,
        PRINTMedia,
        SPOTMedia
    } from "../../utilities/MediaList";
    const EmailRegEx = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[x01-x08x0bx0cx0e-x1fx21x23-x5bx5d-x7f]|[x01-x09x0bx0cx0e-x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9]{2,}(?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[x01-x08x0bx0cx0e-x1fx21-x5ax53-x7f]|\\[x01-x09x0bx0cx0e-\x7f])+)\])$/
    const UrlRegEx = /^(?:(?:https?|ftp):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i
    const UrlWithoutProtocol = helpers.regex('UrlWithoutProtocol', UrlRegEx)
    const EmailWithoutCase = helpers.regex('EmailWithoutCase', EmailRegEx)
    import {mapGetters} from 'vuex';
    // import OmniCustomSelect from "../Common/OmniCustomSelect.vue";
    //const minDate = vuelidate.withParams({minDate: currentDate.format('DD.MM.YYYY')}, value => moment(value\, 'DD.MM.YYYY', true).isSameOrAfter(currentDate))
    export default {
        name: "WireTransferPaymentInfo",
        components: {
            vueDropzone: vue2Dropzone,
            // OmniCustomSelect
        },
        validations: {
            RequestedTransferDate: {
                required,
                minDate(date) {
                    return moment(moment(date).format("MM.DD.YYYY")).isSameOrAfter(currentDate.format("MM.DD.YYYY"));
                }
            },
            WireTransfer: {

                NewVendor: {
                    required
                },
                PaymentType: {
                    required
                },
                CompanyName: {
                    required
                },
                VendorSystem: {
                    required
                },
                MediaType: {
                    required: requiredIf(function (model) {
                        return model.VendorSystem == "DDS";
                    })
                },
                VendorCode: {
                    required: requiredIf(function (model) {
                        return model.PaymentType !== "Payroll";
                    })
                },
                TransferType: {
                    required
                },
                DebitCompany: {
                    required
                },
                Beneficiary: {
                    required
                },
                BeneficiaryStreetAddress: {
                    required
                },
                BeneficiaryCountry: {
                    required
                },
                BeneficiaryCity: {
                    required
                },
                BeneficiaryZipCode: {
                    required
                },
                BeneficiaryReference: {
                    required
                },
                CreditAccountName: {
                    required
                },
                CreditAccountNumber: {
                    required
                },
                Currency: {
                    required
                },
                BankName: {
                    required
                },
                BankCountry: {
                    required
                },
                BankBIC: {
                    required
                },
                BankBICType: {
                    required
                },
                InvoiceCurrency: {
                    required
                },
                TotalInvoiceNumberAmount: {
                    required,
                    max: maxValue(25000000)
                },
                // RequestedTransferDate: {
                //     required,
                //     minDate(date) {
                //         return moment(moment(date).format("MM.DD.YYYY")).isSameOrAfter(currentDate.format("MM.DD.YYYY"));
                //     }
                // },
                PayingBankCharges: {
                    required
                },
                ReceivingBankCharges: {
                    required
                },
                RemittanceAdvice: {
                    required
                },
                Paythrough: {
                    required
                },
                PaythroughBankName: {
                    required: requiredIf(function (model) {
                        return model.Paythrough == "Yes";
                    })
                },
                PaythroughBankCountry: {
                    required: requiredIf(function (model) {
                        return model.Paythrough == "Yes";
                    })
                },
                PaythroughBICType: {
                    required: requiredIf(function (model) {
                        return model.Paythrough == "Yes";
                    })
                },
                PaythroughBIC: {
                    required: requiredIf(function (model) {
                        return model.Paythrough == "Yes";
                    })
                },
                // AuthorizedBy: {
                //     required
                // },
                Files: {
                    required,
                    minLength: minLength(1)
                },
                ResubmitNotes: {
                    required: requiredIf(function () {
                        return this.dataChanged == true;
                    })
                },
                VendorName: {
                    required: requiredIf(function (model) {
                        return model.NewVendor == "Yes";
                    })

                },
                VendorEmail: {
                    required: requiredIf(function (model) {
                        return model.NewVendor == "Yes";
                    }),
                    email: EmailWithoutCase
                },
                VendorPhoneNumber: {
                    required: requiredIf(function (model) {
                        return model.NewVendor == "Yes";
                    }),
                },
                VendorRole: {
                    required: requiredIf(function (model) {
                        return model.NewVendor == "Yes";
                    }),
                },
                VendorContactInfoObtainedFrom: {
                    required: requiredIf(function (model) {
                        return model.NewVendor == "Yes";
                    }),
                },
                VendorInfoObtainedWebsite: {
                    required: requiredIf(function (model) {
                        return model.VendorContactInfoObtainedFrom == 'Website';
                    }),
                    url: UrlWithoutProtocol
                },
                OMGEmpolyeeName: {
                    required: requiredIf(function (model) {
                        return model.VendorContactInfoObtainedFrom == 'ReferredFromOMGEmployee';
                    })
                },
                OMGEmpolyeeRole: {
                    required: requiredIf(function (model) {
                        return model.VendorContactInfoObtainedFrom == 'ReferredFromOMGEmployee';
                    })
                }

            }
        },
        data() {
            return {
                TemplateName:'',
                Description: '',
                showTemplateModel:false,
                closeTemplateModel:false,
                OnSelectKeyCodes:[9],
                CompanyDebitCompanyMapping: [{
                        label: 'Accuen',
                        value: 'RESOLUTION MEDIA INC'
                    },
                    {
                        label: 'Annalect',
                        value: 'OMD USA'
                    },
                    {
                        label: 'Content Collective',
                        value: 'OMD USA'
                    },
                    {
                        label: 'Fan Connect',
                        value: 'OMD USA'
                    },
                    {
                        label: 'Hearts & Science',
                        value: 'HEARTS AND SCIENCE LLC'
                    },
                    {
                        label: 'Hearts & Science LATAM',
                        value: 'HEARTS AND SCIENCE LLC'
                    },
                    {
                        label: 'Hearts & Science Pathway',
                        value: 'HEARTS AND SCIENCE LLC'
                    },
                    {
                        label: 'OMA',
                        value: 'OUTDOOR MEDIA ALLIANCE'
                    },
                    {
                        label: 'OMD',
                        value: 'OMD USA'
                    },
                    {
                        label: 'OMD Latin America',
                        value: 'OMD USA'
                    },
                    {
                        label: 'OMD MAS',
                        value: 'OMD USA'
                    },
                    {
                        label: 'OMG 23',
                        value: 'MEDIA SERVICES INC. DBA OMD ENTERTAINMENT INC.'
                    },
                    {
                        label: 'OMG Holdings',
                        value: 'OMD USA'
                    },
                    {
                        label: 'Omnet',
                        value: 'OMNET LLC'
                    },
                    {
                        label: 'Optimum Sports',
                        value: 'OMD USA'
                    },
                    {
                        label: 'Outdoor Imaging',
                        value: 'OUTDOOR MEDIA GROUP LLC'
                    },
                    {
                        label: 'Outdoor Media Group',
                        value: 'OUTDOOR MEDIA GROUP LLC'
                    },
                    {
                        label: 'Pathway',
                        value: 'OMD USA'
                    },
                    {
                        label: 'PHD',
                        value: 'PHD MEDIA USA'
                    },
                    {
                        label: 'PHD Latin America',
                        value: 'PHD MEDIA USA'
                    },
                    {
                        label: 'Platform Logistics',
                        value: 'OMD USA'
                    },
                    {
                        label: 'Prometheus',
                        value: 'PROMETHEUS MEDIA INC'
                    },
                    {
                        label: 'Resolution Media',
                        value: 'RESOLUTION MEDIA INC'
                    },
                    {
                        label: 'Doremus',
                        value: 'OMD USA'
                    },
                    {
                        label: 'Merkley + Partners',
                        value: 'OMD USA'
                    },
                    {
                        label: 'JUMP450 MEDIA LLC',
                        value: 'JUMP450 MEDIA LLC'
                    },
                    {
                        label: 'TRKKN',
                        value: 'TBD'
                    },

                ],
                UniqueTabId: window.crypto.randomUUID(),
                isDataLoaded: false,
                dataChanged: false,
                closeFilterIcon: require("@/assets/Icons/close-filter-icon.svg"),
                // customYearRange:[{
                //     label:"2021",value:2021
                // }],
                VendorContactInfoObtainedFromOptions: [{
                    label: 'Website',
                    value: 'Website'
                }, {
                    label: 'Referred From OMG Employee',
                    value: 'ReferredFromOMGEmployee'
                }, {
                    label: 'Invoice',
                    value: 'Invoice'
                }],
                DebitCompanyAndAccountNumberMapping: [{
                        label: 'OMD USA',
                        value: 'BAM100Q1'
                    },
                    {
                        label: 'PHD MEDIA USA',
                        value: 'BAM10610'
                    },
                    {
                        label: 'OUTDOOR MEDIA GROUP LLC',
                        value: 'BAM50154'
                    },
                    {
                        label: 'PHD MEDIA - MERCEDES',
                        value: 'BAM50157'
                    },
                    {
                        label: 'PROMETHEUS MEDIA INC',
                        value: 'BAM50239'
                    },
                    {
                        label: 'OUTDOOR MEDIA ALLIANCE',
                        value: 'BAM50260'
                    },
                    {
                        label: 'RFESOLUTION MEDIA INC',
                        value: 'BAM50269'
                    },
                    {
                        label: 'PHD MEDIA - HEWLETT PACKARD',
                        value: 'BAM50395'
                    },
                    {
                        label: 'PHD MEDIA - GAP',
                        value: 'BAM50509'
                    },
                    {
                        label: 'MEDIA SERVICES INC. DBA OMD ENTERTAINMENT INC.',
                        value: 'BAM50553'
                    },
                    {
                        label: 'OMNET LLC',
                        value: 'BAM50556'
                    },
                    {
                        label: 'HEARTS AND SCIENCE LLC',
                        value: 'BAM50674'
                    },
                    {
                        label: 'OMD MITSUBISHI',
                        value: 'BAM50755'
                    },
                    {
                        label: 'JUMP 450',
                        value: 'BAM50913'
                    },
                    {
                        label: 'DOREMUS',
                        value: 'BAM100Q1'
                    },
                    {
                        label: 'MERKLEY + PARTNERS',
                        value: 'BAM100Q1'
                    },
                ],
                CountryOptions: [{
                        label: 'AF - Afghanistan',
                        value: 'AF'
                    },
                    {
                        label: 'AL - Albania',
                        value: 'AL'
                    },
                    {
                        label: 'DZ - Algeria',
                        value: 'DZ'
                    },
                    {
                        label: 'AS - American Samoa',
                        value: 'AS'
                    },
                    {
                        label: 'AD - Andorra',
                        value: 'AD'
                    },
                    {
                        label: 'AO - Angola',
                        value: 'AO'
                    },
                    {
                        label: 'AI - Anguilla',
                        value: 'AI'
                    },
                    {
                        label: 'AQ - Antarctica',
                        value: 'AQ'
                    },
                    {
                        label: 'AG - Antigua and Barbuda',
                        value: 'AG'
                    },
                    {
                        label: 'AR - Argentina',
                        value: 'AR'
                    },
                    {
                        label: 'AM - Armenia',
                        value: 'AM'
                    },
                    {
                        label: 'AW - Aruba',
                        value: 'AW'
                    },
                    {
                        label: 'AU - Australia',
                        value: 'AU'
                    },
                    {
                        label: 'AT - Austria',
                        value: 'AT'
                    },
                    {
                        label: 'AZ - Azerbaijan',
                        value: 'AZ'
                    },
                    {
                        label: 'BS - Bahamas',
                        value: 'BS'
                    },
                    {
                        label: 'BH - Bahrein',
                        value: 'BH'
                    },
                    {
                        label: 'BD - Bangladesh',
                        value: 'BD'
                    },
                    {
                        label: 'BB - Barbados',
                        value: 'BB'
                    },
                    {
                        label: 'BY - Belarus',
                        value: 'BY'
                    },
                    {
                        label: 'BE - Belgium',
                        value: 'BE'
                    },
                    {
                        label: 'BZ - Belize',
                        value: 'BZ'
                    },
                    {
                        label: 'BJ - Benin',
                        value: 'BJ'
                    },
                    {
                        label: 'BM - Bermuda',
                        value: 'BM'
                    },
                    {
                        label: 'BT - Bhutan',
                        value: 'BT'
                    },
                    {
                        label: 'XB - BIS',
                        value: 'XB'
                    },
                    {
                        label: 'BO - Bolivia',
                        value: 'BO'
                    },
                    {
                        label: 'BA - Bosnia Herzegovina',
                        value: 'BA'
                    },
                    {
                        label: 'BW - Botswana',
                        value: 'BW'
                    },
                    {
                        label: 'BV - Bouvet Island',
                        value: 'BV'
                    },
                    {
                        label: 'BR - Brazil',
                        value: 'BR'
                    },
                    {
                        label: 'BN - Brunei',
                        value: 'BN'
                    },
                    {
                        label: 'BG - Bulgaria',
                        value: 'BG'
                    },
                    {
                        label: 'BF - Burkina Faso',
                        value: 'BF'
                    },
                    {
                        label: 'BI - Burundi',
                        value: 'BI'
                    },
                    {
                        label: 'KH - Cambodia',
                        value: 'KH'
                    },
                    {
                        label: 'CM - Cameroon',
                        value: 'CM'
                    },
                    {
                        label: 'CA - Canada',
                        value: 'CA'
                    },
                    {
                        label: 'CV - Cape Verde',
                        value: 'CV'
                    },
                    {
                        label: 'BQ - Caribbean Netherlands',
                        value: 'BQ'
                    },
                    {
                        label: 'KY - Cayman Islands',
                        value: 'KY'
                    },
                    {
                        label: 'CF - Central Africa',
                        value: 'CF'
                    },
                    {
                        label: 'TD - Chad',
                        value: 'TD'
                    },
                    {
                        label: 'IO - Chagos Islands',
                        value: 'IO'
                    },
                    {
                        label: 'JE - Channel Islands Jersey',
                        value: 'JE'
                    },
                    {
                        label: 'CL - Chile',
                        value: 'CL'
                    },
                    {
                        label: 'CN - China',
                        value: 'CN'
                    },
                    {
                        label: 'CX - Christmas Island',
                        value: 'CX'
                    },
                    {
                        label: 'CC - Cocos (Keeling) Island',
                        value: 'CC'
                    },
                    {
                        label: 'CO - Colombia',
                        value: 'CO'
                    },
                    {
                        label: 'KM - Comoros',
                        value: 'KM'
                    },
                    {
                        label: 'CG - Congo',
                        value: 'CG'
                    },
                    {
                        label: 'CK - Cook islands',
                        value: 'CK'
                    },
                    {
                        label: 'CR - Costa Rica',
                        value: 'CR'
                    },
                    {
                        label: "CI - Cote D'Ivoire",
                        value: 'CI '
                    },
                    {
                        label: 'HR - Croatia',
                        value: 'HR'
                    },
                    {
                        label: 'CU - Cuba',
                        value: 'CU'
                    },
                    {
                        label: 'CW - Curacao',
                        value: 'CW'
                    },
                    {
                        label: 'CY - Cyprus',
                        value: 'CY'
                    },
                    {
                        label: 'CZ - Czech Republic',
                        value: 'CZ'
                    },
                    {
                        label: "KP - Democratic people's Republic of Korea",
                        value: 'KP '
                    },
                    {
                        label: 'DK - Denmark',
                        value: 'DK'
                    },
                    {
                        label: 'DJ - Djibouti',
                        value: 'DJ'
                    },
                    {
                        label: 'DM - Dominica',
                        value: 'DM'
                    },
                    {
                        label: 'DO - Dominican Republic',
                        value: 'DO'
                    },
                    {
                        label: 'AN - Dutch Antilles',
                        value: 'AN'
                    },
                    {
                        label: 'TP - East Timor',
                        value: 'TP'
                    },
                    {
                        label: 'XE - EEC',
                        value: 'XE'
                    },
                    {
                        label: 'EG - Egypt',
                        value: 'EG'
                    },
                    {
                        label: 'XF - EIB',
                        value: 'XF'
                    },
                    {
                        label: 'SV - El Salvador',
                        value: 'SV'
                    },
                    {
                        label: 'EC - Equador',
                        value: 'EC'
                    },
                    {
                        label: 'GQ - Equatorial Guinea',
                        value: 'GQ'
                    },
                    {
                        label: 'ER - Eritrea',
                        value: 'ER'
                    },
                    {
                        label: 'EE - Estonia',
                        value: 'EE'
                    },
                    {
                        label: 'ET - Ethiopia',
                        value: 'ET'
                    },
                    {
                        label: 'FK - Falkland Islands',
                        value: 'FK'
                    },
                    {
                        label: 'FO - Faroe Islands',
                        value: 'FO'
                    },
                    {
                        label: 'FM - Federated States of Micronesia',
                        value: 'FM'
                    },
                    {
                        label: 'FJ - Fiji',
                        value: 'FJ'
                    },
                    {
                        label: 'FI - Finland',
                        value: 'FI'
                    },
                    {
                        label: 'FR - France',
                        value: 'FR'
                    },
                    {
                        label: 'GF - French Guiana',
                        value: 'GF'
                    },
                    {
                        label: 'PF - French Polynesia',
                        value: 'PF'
                    },
                    {
                        label: 'TF - French Southern Territories',
                        value: 'TF'
                    },
                    {
                        label: 'GA - Gabon',
                        value: 'GA'
                    },
                    {
                        label: 'GM - Gambia',
                        value: 'GM'
                    },
                    {
                        label: 'GE - Georgia',
                        value: 'GE'
                    },
                    {
                        label: 'DE - Germany',
                        value: 'DE'
                    },
                    {
                        label: 'GH - Ghana',
                        value: 'GH'
                    },
                    {
                        label: 'GI - Gibraltar',
                        value: 'GI'
                    },
                    {
                        label: 'GR - Greece',
                        value: 'GR'
                    },
                    {
                        label: 'GL - Greenland',
                        value: 'GL'
                    },
                    {
                        label: 'GD - Grenada',
                        value: 'GD'
                    },
                    {
                        label: 'GP - Guadeloupe',
                        value: 'GP'
                    },
                    {
                        label: 'GU - Guam',
                        value: 'GU'
                    },
                    {
                        label: 'GT - Guatemala',
                        value: 'GT'
                    },
                    {
                        label: 'GG - Guernsey',
                        value: 'GG'
                    },
                    {
                        label: 'GN - Guinea',
                        value: 'GN'
                    },
                    {
                        label: 'GW - Guinea Bissau',
                        value: 'GW'
                    },
                    {
                        label: 'GY - Guyana',
                        value: 'GY'
                    },
                    {
                        label: 'HT - Haiti',
                        value: 'HT'
                    },
                    {
                        label: 'HM - Heard Island and McDonald Islands',
                        value: 'HM'
                    },
                    {
                        label: 'VA - Holy See (Vatican City State)',
                        value: 'VA'
                    },
                    {
                        label: 'HN - Honduras',
                        value: 'HN'
                    },
                    {
                        label: 'HK - Hong Kong',
                        value: 'HK'
                    },
                    {
                        label: 'HU - Hungary',
                        value: 'HU'
                    },
                    {
                        label: 'IS - Iceland',
                        value: 'IS'
                    },
                    {
                        label: 'IN - India',
                        value: 'IN'
                    },
                    {
                        label: 'ID - Indonesia',
                        value: 'ID'
                    },
                    {
                        label: 'IR - Iran',
                        value: 'IR'
                    },
                    {
                        label: 'IQ - Iraq',
                        value: 'IQ'
                    },
                    {
                        label: 'IE - Ireland',
                        value: 'IE'
                    },
                    {
                        label: 'IM - Isle of Man',
                        value: 'IM'
                    },
                    {
                        label: 'IL - Israel',
                        value: 'IL'
                    },
                    {
                        label: 'IT - Italy',
                        value: 'IT'
                    },
                    {
                        label: 'JM - Jamaica',
                        value: 'JM'
                    },
                    {
                        label: 'JP - Japan',
                        value: 'JP'
                    },
                    {
                        label: 'JO - Jordan',
                        value: 'JO'
                    },
                    {
                        label: 'KZ - Kazakstan',
                        value: 'KZ'
                    },
                    {
                        label: 'KE - Kenya',
                        value: 'KE'
                    },
                    {
                        label: 'KI - Kiribati',
                        value: 'KI'
                    },
                    {
                        label: 'KW - Kuwait',
                        value: 'KW'
                    },
                    {
                        label: 'KG - Kyrgystan',
                        value: 'KG'
                    },
                    {
                        label: "LA - Lao People's Republic of",
                        value: 'LA '
                    },
                    {
                        label: 'LV - Latvia',
                        value: 'LV'
                    },
                    {
                        label: 'LB - Lebanon',
                        value: 'LB'
                    },
                    {
                        label: 'LS - Lesotho',
                        value: 'LS'
                    },
                    {
                        label: 'LR - Liberia',
                        value: 'LR'
                    },
                    {
                        label: 'LI - Liechtenstein',
                        value: 'LI'
                    },
                    {
                        label: 'LT - Lithuania',
                        value: 'LT'
                    },
                    {
                        label: 'LU - Luxembourg',
                        value: 'LU'
                    },
                    {
                        label: 'LY - Lybyan Arab Jamahiriya',
                        value: 'LY'
                    },
                    {
                        label: 'MO - Macau',
                        value: 'MO'
                    },
                    {
                        label: 'MK - Macedonia',
                        value: 'MK'
                    },
                    {
                        label: 'MG - Madagaskar',
                        value: 'MG'
                    },
                    {
                        label: 'MW - Malawi',
                        value: 'MW'
                    },
                    {
                        label: 'MY - Malaysia',
                        value: 'MY'
                    },
                    {
                        label: 'MV - Maldives',
                        value: 'MV'
                    },
                    {
                        label: 'ML - Mali',
                        value: 'ML'
                    },
                    {
                        label: 'MT - Malta',
                        value: 'MT'
                    },
                    {
                        label: 'MH - Marshall Islands',
                        value: 'MH'
                    },
                    {
                        label: 'MQ - Martinique',
                        value: 'MQ'
                    },
                    {
                        label: 'MR - Mauritania',
                        value: 'MR'
                    },
                    {
                        label: 'MU - Mauritius',
                        value: 'MU'
                    },
                    {
                        label: 'YT - Mayotte',
                        value: 'YT'
                    },
                    {
                        label: 'MX - Mexico',
                        value: 'MX'
                    },
                    {
                        label: 'MC - Monaco',
                        value: 'MC'
                    },
                    {
                        label: 'MN - Mongolia',
                        value: 'MN'
                    },
                    {
                        label: 'ME - Montenegro',
                        value: 'ME'
                    },
                    {
                        label: 'MS - Montserrat',
                        value: 'MS'
                    },
                    {
                        label: 'MA - Morocco',
                        value: 'MA'
                    },
                    {
                        label: 'MZ - Mozambique',
                        value: 'MZ'
                    },
                    {
                        label: 'MM - Myanmar(Burma)',
                        value: 'MM'
                    },
                    {
                        label: 'NA - Namibia',
                        value: 'NA'
                    },
                    {
                        label: 'NR - Nauru',
                        value: 'NR'
                    },
                    {
                        label: 'NP - Nepal',
                        value: 'NP'
                    },
                    {
                        label: 'NL - Netherlands',
                        value: 'NL'
                    },
                    {
                        label: 'NT - Neutral zone',
                        value: 'NT'
                    },
                    {
                        label: 'NC - New Caledonia',
                        value: 'NC'
                    },
                    {
                        label: 'NZ - New Zealand',
                        value: 'NZ'
                    },
                    {
                        label: 'NI - Nicaragua',
                        value: 'NI'
                    },
                    {
                        label: 'NE - Niger',
                        value: 'NE'
                    },
                    {
                        label: 'NG - Nigeria',
                        value: 'NG'
                    },
                    {
                        label: 'NU - Niue',
                        value: 'NU'
                    },
                    {
                        label: 'NF - Norfolk Island',
                        value: 'NF'
                    },
                    {
                        label: 'KF - North Korea',
                        value: 'KF'
                    },
                    {
                        label: 'MP - Northern Mariana Islands',
                        value: 'MP'
                    },
                    {
                        label: 'NO - Norway',
                        value: 'NO'
                    },
                    {
                        label: 'PS - Occupied Palestinian Territory',
                        value: 'PS'
                    },
                    {
                        label: 'XO - OESO',
                        value: 'XO'
                    },
                    {
                        label: 'OM - Oman',
                        value: 'OM'
                    },
                    {
                        label: 'PK - Pakistan',
                        value: 'PK'
                    },
                    {
                        label: 'PW - Palau',
                        value: 'PW'
                    },
                    {
                        label: 'PA - Panama',
                        value: 'PA'
                    },
                    {
                        label: 'PG - Papua New Guinea',
                        value: 'PG'
                    },
                    {
                        label: 'PY - Paraguay',
                        value: 'PY'
                    },
                    {
                        label: 'PE - Peru',
                        value: 'PE'
                    },
                    {
                        label: 'PH - Philippines',
                        value: 'PH'
                    },
                    {
                        label: 'PN - Pitcairn Islands',
                        value: 'PN'
                    },
                    {
                        label: 'PL - Poland',
                        value: 'PL'
                    },
                    {
                        label: 'PT - Portugal',
                        value: 'PT'
                    },
                    {
                        label: 'PR - Puerto Rico',
                        value: 'PR'
                    },
                    {
                        label: 'QA - Qatar',
                        value: 'QA'
                    },
                    {
                        label: 'KR - Republic of Korea',
                        value: 'KR'
                    },
                    {
                        label: 'XK - Republic of Kosova',
                        value: 'XK'
                    },
                    {
                        label: 'MD - Republic of Moldavia',
                        value: 'MD'
                    },
                    {
                        label: 'RE - Reunion',
                        value: 'RE'
                    },
                    {
                        label: 'RO - Romania',
                        value: 'RO'
                    },
                    {
                        label: 'RU - Russian Federation',
                        value: 'RU'
                    },
                    {
                        label: 'RW - Rwanda',
                        value: 'RW'
                    },
                    {
                        label: 'SH - Saint Helena',
                        value: 'SH'
                    },
                    {
                        label: 'KN - Saint Kitts-Nevis',
                        value: 'KN'
                    },
                    {
                        label: 'LC - Saint Lucia',
                        value: 'LC'
                    },
                    {
                        label: 'PM - Saint Pierre and Miquelon',
                        value: 'PM'
                    },
                    {
                        label: 'VC - Saint Vincent and Grenadines',
                        value: 'VC'
                    },
                    {
                        label: 'WS - Samoa',
                        value: 'WS'
                    },
                    {
                        label: 'SM - San Marino',
                        value: 'SM'
                    },
                    {
                        label: 'ST - Sao Tome and Principe',
                        value: 'ST'
                    },
                    {
                        label: 'SA - Saudi Arabia',
                        value: 'SA'
                    },
                    {
                        label: 'SN - Senegal',
                        value: 'SN'
                    },
                    {
                        label: 'RS - Serbia',
                        value: 'RS'
                    },
                    {
                        label: 'CS - Serbia & Montenegro',
                        value: 'CS'
                    },
                    {
                        label: 'SC - Seychelles',
                        value: 'SC'
                    },
                    {
                        label: 'SL - Sierra Leone',
                        value: 'SL'
                    },
                    {
                        label: 'SG - Singapore',
                        value: 'SG'
                    },
                    {
                        label: 'SX - Sint Maarten',
                        value: 'SX'
                    },
                    {
                        label: 'SK - Slovakia',
                        value: 'SK'
                    },
                    {
                        label: 'SI - Slovenia',
                        value: 'SI'
                    },
                    {
                        label: 'SB - Solomon islands',
                        value: 'SB'
                    },
                    {
                        label: 'SO - Somalia',
                        value: 'SO'
                    },
                    {
                        label: 'ZA - South Africa',
                        value: 'ZA'
                    },
                    {
                        label: 'ES - Spain',
                        value: 'ES'
                    },
                    {
                        label: 'LK - Sri Lanka',
                        value: 'LK'
                    },
                    {
                        label: 'SD - Sudan',
                        value: 'SD'
                    },
                    {
                        label: 'SR - Suriname',
                        value: 'SR'
                    },
                    {
                        label: 'SJ - Svalbard and Jan Mayen',
                        value: 'SJ'
                    },
                    {
                        label: 'SZ - Swaziland',
                        value: 'SZ'
                    },
                    {
                        label: 'SE - Sweden',
                        value: 'SE'
                    },
                    {
                        label: 'CH - Switzerland',
                        value: 'CH'
                    },
                    {
                        label: 'SY - Syrian Arab Republic',
                        value: 'SY'
                    },
                    {
                        label: 'TW - Taiwan',
                        value: 'TW'
                    },
                    {
                        label: 'TJ - Tajikistan',
                        value: 'TJ'
                    },
                    {
                        label: 'TZ - Tanzania',
                        value: 'TZ'
                    },
                    {
                        label: 'TH - Thailand',
                        value: 'TH'
                    },
                    {
                        label: 'CD - The democratic replubic of Congo',
                        value: 'CD'
                    },
                    {
                        label: 'GS - the south sandwich islands South Georgia',
                        value: 'GS'
                    },
                    {
                        label: 'TG - Togo',
                        value: 'TG'
                    },
                    {
                        label: 'TK - Tokelau islands',
                        value: 'TK'
                    },
                    {
                        label: 'TO - Tonga',
                        value: 'TO'
                    },
                    {
                        label: 'TT - Trinidad and Tobago',
                        value: 'TT'
                    },
                    {
                        label: 'TN - Tunisia',
                        value: 'TN'
                    },
                    {
                        label: 'TR - Turkey',
                        value: 'TR'
                    },
                    {
                        label: 'TM - Turkmenistan',
                        value: 'TM'
                    },
                    {
                        label: 'TC - Turks and Caicos islands',
                        value: 'TC'
                    },
                    {
                        label: 'TU - Tuvalu',
                        value: 'TU'
                    },
                    {
                        label: 'UG - Uganda',
                        value: 'UG'
                    },
                    {
                        label: 'UA - Ukraine',
                        value: 'UA'
                    },
                    {
                        label: 'AE - United Arab Emirates',
                        value: 'AE'
                    },
                    {
                        label: 'GB - United Kingdom',
                        value: 'GB'
                    },
                    {
                        label: 'XU - United Nations',
                        value: 'XU'
                    },
                    {
                        label: 'US - United States',
                        value: 'US'
                    },
                    {
                        label: 'UM - United States minor outlying islands',
                        value: 'UM'
                    },
                    {
                        label: 'NN - Unknown',
                        value: 'NN'
                    },
                    {
                        label: 'UY - Uruguay',
                        value: 'UY'
                    },
                    {
                        label: 'UZ - Uzbekistan',
                        value: 'UZ'
                    },
                    {
                        label: 'VU - Vanuatu',
                        value: 'VU'
                    },
                    {
                        label: 'VE - Venezuela',
                        value: 'VE'
                    },
                    {
                        label: 'VN - Vietnam',
                        value: 'VN'
                    },
                    {
                        label: 'VG - Virgin Islands (British)',
                        value: 'VG'
                    },
                    {
                        label: 'VI - Virgin Islands (US)',
                        value: 'VI'
                    },
                    {
                        label: 'WF - Wallis and Futuna',
                        value: 'WF'
                    },
                    {
                        label: 'EH - Western Sahara',
                        value: 'EH'
                    },
                    {
                        label: 'YE - Yemen',
                        value: 'YE'
                    },
                    {
                        label: 'YU - Yugoslavia',
                        value: 'YU'
                    },
                    {
                        label: 'ZR - Zaire',
                        value: 'ZR'
                    },
                    {
                        label: 'ZM - Zambia',
                        value: 'ZM'
                    },
                    {
                        label: 'ZW - Zimbabwe',
                        value: 'ZW'
                    },

                ],
                // AuthorizedOptions: [],
                Watchers: [],
                CreateAnotherRequest: null,
                PaymentTypeOptions: [],
                CompanyNameOptions: [],
                DebitCompanyOptions: [{
                        value: "OMD USA",
                        label: "OMD USA"
                    },
                    {
                        value: "PHD MEDIA USA",
                        label: "PHD MEDIA USA"
                    },
                    {
                        value: "OUTDOOR MEDIA GROUP LLC",
                        label: "OUTDOOR MEDIA GROUP LLC"
                    },
                    {
                        value: "PHD MEDIA - MERCEDES",
                        label: "PHD MEDIA - MERCEDES"
                    },
                    {
                        value: "PROMETHEUS MEDIA INC",
                        label: "PROMETHEUS MEDIA INC"
                    },
                    {
                        value: "OUTDOOR MEDIA ALLIANCE",
                        label: "OUTDOOR MEDIA ALLIANCE"
                    },
                    {
                        value: "RESOLUTION MEDIA INC",
                        label: "RESOLUTION MEDIA INC"
                    },
                    {
                        value: "PHD MEDIA - HEWLETT PACKARD",
                        label: "PHD MEDIA - HEWLETT PACKARD"
                    },
                    {
                        value: "PHD MEDIA - GAP",
                        label: "PHD MEDIA - GAP"
                    },
                    {
                        value: "MEDIA SERVICES INC. DBA OMD ENTERTAINMENT INC.",
                        label: "MEDIA SERVICES INC. DBA OMD ENTERTAINMENT INC."
                    },
                    {
                        value: "OMNET LLC",
                        label: "OMNET LLC"
                    },
                    {
                        value: "HEARTS AND SCIENCE LLC",
                        label: "HEARTS AND SCIENCE LLC"
                    },
                    {
                        value: "OMD MITSUBISHI",
                        label: "OMD MITSUBISHI"
                    },
                    {
                        value: "JUMP 450",
                        label: "JUMP 450"
                    },
                    {
                        value: "DOREMUS",
                        label: "DOREMUS"
                    },
                    {
                        value: "MERKLEY + PARTNERS",
                        label: "MERKLEY + PARTNERS"
                    }
                ],
                BankBICTypeOptions: [{
                        value: "FEDWIRE",
                        label: "FEDWIRE"
                    },
                    {
                        value: "SWIFT",
                        label: "SWIFT"
                    }
                ],
                CurrencyOptions: [],
                InvoiceCurrencyOptions: [],
                InvoiceCurrencyOptionsOriginal: [{
                        label: 'USD - US DOLLAR',
                        value: 'USD'
                    },
                    {
                        label: 'CAD - CANADIAN DOLLAR',
                        value: 'CAD'
                    },
                    {
                        label: 'AED - UAE DIRHAM',
                        value: 'AED'
                    },
                    {
                        label: 'AUD - AUSTRALIAN DOLLAR',
                        value: 'AUD'
                    },
                    {
                        label: 'BHD - BAHRAIN DINAR',
                        value: 'BHD'
                    },
                    {
                        label: 'BRL - BRAZILIAN REAL',
                        value: 'BRL'
                    },

                    {
                        label: 'CLP - CHILEAN PESOS',
                        value: 'CLP'
                    },
                    {
                        label: 'CNY - CHINESE RENMINBI',
                        value: 'CNY'
                    },
                    {
                        label: 'CRC - Costa Rican Colon',
                        value: 'CRC'
                    },
                    {
                        label: 'HRK - CROATIAN KUNA',
                        value: 'HRK'
                    },
                    {
                        label: 'CYP - CYPRUS POUNDS',
                        value: 'CYP'
                    },
                    {
                        label: 'CZK - CZECH KRONER',
                        value: 'CZK'
                    },
                    {
                        label: 'DKK - DANISH KRONE',
                        value: 'DKK'
                    },
                    {
                        label: 'EGP - EGYPTIAN POUND',
                        value: 'EGP'
                    },
                    {
                        label: 'EEK - ESTONIAN KROON',
                        value: 'EEK'
                    },
                    {
                        label: 'EUR - EURO',
                        value: 'EUR'
                    },
                    {
                        label: 'GBP - GREAT BRITAIN POUND',
                        value: 'GBP'
                    },
                    {
                        label: 'GTQ - GUATEMALAN QUETZAL',
                        value: 'GTQ'
                    },
                    {
                        label: 'HKD - HONG KONG DOLLAR',
                        value: 'HKD'
                    },
                    {
                        label: 'HUF - HUNGARIAN FORINT',
                        value: 'HUF'
                    },
                    {
                        label: 'INR - INDIAN RUPEE',
                        value: 'INR'
                    },
                    {
                        label: 'IDR - INDONESIAN RUPEE',
                        value: 'IDR'
                    },
                    {
                        label: 'ILS - ISRAELI SHEKEL',
                        value: 'ILS'
                    },
                    {
                        label: 'JPY - JAPANESE YEN',
                        value: 'JPY'
                    },
                    {
                        label: 'KWD - KUWAITI DINAR',
                        value: 'KWD'
                    },
                    {
                        label: 'LVL - LATVIAN LATS',
                        value: 'LVL'
                    },
                    {
                        label: 'LTL - LITHUANIAN LITAS',
                        value: 'LTL'
                    },
                    {
                        label: 'MYR - MALAYSIAN RINGGIT',
                        value: 'MYR'
                    },
                    {
                        label: 'MTL - MALTESE LIRA',
                        value: 'MTL'
                    },
                    {
                        label: 'MXN - MEXICAN PESOS',
                        value: 'MXN'
                    },
                    {
                        label: 'MAD - MOROCCAN DIRHAM',
                        value: 'MAD'
                    },
                    {
                        label: 'TRY - NEW TURKISH LIRA',
                        value: 'TRY'
                    },
                    {
                        label: 'NZD - NEW ZEALAND DOLLAR',
                        value: 'NZD'
                    },
                    {
                        label: 'NOK - NORWEGIAN KRONE',
                        value: 'NOK'
                    },
                    {
                        label: 'OMR - OMANI RIAL',
                        value: 'OMR'
                    },
                    {
                        label: 'PKR - PAKISTANI RUPEE',
                        value: 'PKR'
                    },
                    {
                        label: 'PEN - Peruvian Nuevo Sol',
                        value: 'PEN'
                    },
                    {
                        label: 'PHP - PHILLIPPINE PESO',
                        value: 'PHP'
                    },
                    {
                        label: 'PLN - POLISH ZLOTY',
                        value: 'PLN'
                    },
                    {
                        label: 'QAR - QATAR RIYAL',
                        value: 'QAR'
                    },
                    {
                        label: 'RON - ROMANIAN LEI',
                        value: 'RON'
                    },
                    {
                        label: 'RUB - ROUBLE',
                        value: 'RUB'
                    },
                    {
                        label: 'SAR - SAUDI RIYAL',
                        value: 'SAR'
                    },
                    {
                        label: 'SGD - SINGAPORE DOLLAR',
                        value: 'SGD'
                    },
                    {
                        label: 'SKK - SLOVAKIAN KORUNA',
                        value: 'SKK'
                    },
                    {
                        label: 'ZAR - SOUTH AFRICAN RAND',
                        value: 'ZAR'
                    },
                    {
                        label: 'KRW - SOUTH KOREAN WON',
                        value: 'KRW'
                    },
                    {
                        label: 'SEK - SWEDISH KRONA',
                        value: 'SEK'
                    },
                    {
                        label: 'CHF - SWISS FRANC',
                        value: 'CHF'
                    },
                    {
                        label: 'TWD - TAIWANESE DOLLAR',
                        value: 'TWD'
                    },
                    {
                        label: 'THB - THAI BAHT',
                        value: 'THB'
                    },

                    {
                        label: 'UAH - UKRANIAN HRYVNIA',
                        value: 'UAH'
                    },
                    {
                        label: 'YUM - YUGOSLAVIAN NEW DINAR',
                        value: 'YUM'
                    },
                ],
                MediaTypeOptions: [{
                    name: "Net",
                    label: "Net",
                    value: "Net"
                }],
                VendorCodeOptions: [{
                    value: "DB-246-D",
                    label: "DB-246-D"
                }, {
                    value: "AN-244-A",
                    label: "AN-244-A"
                }],
                WireTransferRequestWatchers: [],
                WireTransfer: {
                    ID: 0,
                    TransactionNo: "",
                    WireNo: null,
                    NewVendor: "No",
                    PaymentType: null,
                    CompanyName: null,
                    VendorSystem: "AX",
                    VendorCode: null,
                    MediaType: null,
                    TransferType: "Domestic",
                    DebitCompany: null,
                    DebitAccountNumber: null,
                    Beneficiary: null,
                    BeneficiaryStreetAddress: null,
                    BeneficiaryCountry: "US",
                    BeneficiaryCity: null,
                    BeneficiaryZipCode: null,
                    BeneficiaryReference: null,
                    CreditAccountName: null,
                    CreditAccountNumber: null,
                    Currency: "b19b013b-b07f-4d2d-9692-f3cb154bae0a",
                    BankName: null,
                    BankCountry: null,
                    BankBICType: null,
                    BankBIC: null,
                    InvoiceCurrency: null,
                    TotalInvoiceAmount: null,
                    TotalInvoiceNumberAmount: null,
                    RequestedTransferDate: null,
                    PayingBankCharges: "Remitter",
                    ReceivingBankCharges: "Remitter",
                    RemittanceAdvice: "Yes",
                    Paythrough: "No",
                    PaythroughBankName: null,
                    PaythroughBankCountry: null,
                    PaythroughBICType: null,
                    PaythroughBIC: null,
                    AuthorizedBy: null,
                    WireTransferRequestWatchers: [],
                    Files: [],
                    Notes: null,
                    CreatedDate: new Date(),
                    UpdatedDate: new Date(),
                    Requestor: null,
                     Status: null,
                    OmniflowProcessID: null,
                    OmniflowProjectID: null,
                    ResubmitNotes: null,
                    CFOApprover: null,
                    VendorName: null,
                    VendorEmail: null,
                    VendorPhoneNumber: null,
                    VendorRole: null,
                    VendorContactInfoObtainedFrom: null,
                    VendorInfoObtainedWebsite: null,
                    OMGEmpolyeeName: null,
                    OMGEmpolyeeRole: null

                },
                RequestedTransferDate: new Date(),
                dropzoneOptions: {
                    closeFilterIcon: require("@/assets/Icons/file.png"),
                    previewTemplate: this.template(),
                    url: `${process.env.VUE_APP_API_ROOT}api/v1/WireTransfer/Upload`,
                    uploadMultiple: true,
                    maxFiles: 20,
                    minFiles: 1,
                    maxFilesize: 20,
                    addRemoveLinks: false,
                    parallelUploads: 20,
                    createImageThumbnails: false,
                    autoProcessQueue: false,
                    acceptedFiles: "application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.doc,.docx,.msg",
                    init: function () {
                        this.on("addedfile", function (file) {
                            if (this.files.length > 20) {
                                this.removeFile(file);
                                window.alert("Maximum File Upload limit is 20!");
                            }
                        });
                    },
                },
            }
        },
        props: {
            id: {
                type: Number,
                required: false
            },
            isDraft: {
                type: Boolean,
                required: false,
            },
            isTemplate: {
                type: Boolean,
                required: false,
            }
        },
        watch: {
            "WireTransfer.ID":function(val){
               if(this.isDraft != true && parseInt(val)>0) {
                 if (!(this.WireTransfer.Requestor==this.loggedInUser||(this.UserAgency(this.Request.CompanyName)&&this.canApproveLevel1WireTransferRequest))) {
                    this.$router.push({
                        name: "error",
                    });
                }
               }
            },
            "WireTransfer.NewVendor": function (val) {
                if (val == "No") {
                    this.ResetVendorConatctInfo();
                }
            },
            "WireTransfer.PaymentType": function (val) {
                if (val == "Media" || val == "Vendor Refund") {
                    this.$set(this.WireTransfer, "VendorSystem", 'DDS');
                } else if (val == "Client Refund" || val == "House" || val == "Production") {
                    this.$set(this.WireTransfer, "VendorSystem", 'AX');
                } else if (val == "Payroll") {
                    this.$set(this.WireTransfer, "VendorSystem", 'Payroll');
                }

            },
            "WireTransfer.VendorSystem": function (val, old) {
                if (val != "DDS") {
                    this.$set(this.WireTransfer, "MediaType", null);
                } else {
                    if (old != "DDS" && this.WireTransfer.MediaType == null) {
                        this.$set(this.WireTransfer, "VendorCode", null);
                    }
                }

            },
            "WireTransfer.CompanyName": function () {
                //this.LoadWatcherList();
                this.AssignDebitCompany();
                // this.LoadApproverList();

            },
            "WireTransfer.TransferType": function (val) {
                if (val == "Domestic") {
                    //this.$set(this.WireTransfer, "BeneficiaryCountry", "78d70193e1eb493dafcf3f261b7759ef");
                    //this.$set(this.WireTransfer, "Currency", "b19b013b-b07f-4d2d-9692-f3cb154bae0a");
                    this.$set(this.WireTransfer, "BeneficiaryCountry", "US");
                    this.$set(this.WireTransfer, "Currency", "b19b013b-b07f-4d2d-9692-f3cb154bae0a");
                }

            },
            "WireTransfer.Paythrough": function (val) {
                if (val == "No") {
                    this.$set(this.WireTransfer, "PaythroughBankName", null);
                    this.$set(this.WireTransfer, "PaythroughBankCountry", null);
                    this.$set(this.WireTransfer, "PaythroughBICType", null);
                    this.$set(this.WireTransfer, "PaythroughBIC", null);
                }

            },
            "WireTransfer.TotalInvoiceAmount" : function(val) {
                if(val != null && !isNaN(val.toString().replaceAll(',', ''))) {
                    let formatedValue = Number(val.toString().replaceAll(',', ''));
                    this.WireTransfer.TotalInvoiceNumberAmount = formatedValue > 0 ? formatedValue : null;
                }
                else {
                    this.WireTransfer.TotalInvoiceNumberAmount = null;
                }
            }
        },
        beforeDestroy() {
            this.DelteTransacationNumber();
        },
        created() {
            this.$socket.start({
                log: true // Logging is optional but very helpful during development
            })
            this.LoadWatcherList();
            this.LoadDropdownValues();
            this.InvoiceCurrencyOptions = this.InvoiceCurrencyOptionsOriginal.sort((a, b) => {
                let fa = a.label.toLowerCase(),
                    fb = b.label.toLowerCase();
                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            });
            if (this.IsEdit == true) {
                if (this.isDraft == true) {
                    this.LoadDraftFormData();

                }
                else if(this.isTemplate==true)
                {

                    this.LoadTemplateFormData();
                    this.isDataLoaded = true;
                }
                else {
                        this.LoadFormData();
                }
            } else {
                // this.GetTransacationNumber();
                this.isDataLoaded = true;
            }
            if (this.hasPayrollPermission == true) {
                this.PaymentTypeOptions.push({
                    value: "Payroll",
                    label: "Payroll"
                });
                this.$set(this.WireTransfer, "PaymentType", "Payroll");
                this.$set(this.WireTransfer, "VendorSystem", "Payroll");

            } else {
                this.PaymentTypeOptions.push({
                    value: "House",
                    label: "House"
                });
                this.PaymentTypeOptions.push({
                    value: "Media",
                    label: "Media"
                });
                this.PaymentTypeOptions.push({
                    value: "Production",
                    label: "Production"
                });
                this.PaymentTypeOptions.push({
                    value: "Client Refund",
                    label: "Client Refund"
                });
                this.PaymentTypeOptions.push({
                    value: "Vendor Refund",
                    label: "Vendor Refund"
                });
            }

        },
        methods: {
            formatCurrency() {
                let formatAmount = parseFloat(this.WireTransfer.TotalInvoiceAmount);
                if (!isNaN(formatAmount)) {
                    let temp = formatAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                    this.WireTransfer.TotalInvoiceAmount = temp;
                }
                else {
                    this.WireTransfer.TotalInvoiceAmount = null;
                }
                this.$v.WireTransfer.TotalInvoiceNumberAmount.$touch();
            },
            resetFormat(){
                let totalInvoiceAmount = this.WireTransfer.TotalInvoiceAmount;
                if(totalInvoiceAmount != null && !isNaN(totalInvoiceAmount.toString().replaceAll(',', ''))){
                    var formatedValue = Number(totalInvoiceAmount.toString().replaceAll(',', ''));
                    this.WireTransfer.TotalInvoiceAmount = formatedValue > 0 ? formatedValue : null;
                }
                else {
                    this.WireTransfer.TotalInvoiceAmount = null;
                }
            },
            closeDescriptionBox() {
                this.showTemplateModel = false;
            },
            PrintValidation(){
                const params = Object.keys(this.$v.WireTransfer.$params);
                var invalidFields = [];
                for (var x = 0; x < params.length; x++){
                    let name = params[x];
                    if (this.$v.WireTransfer[name].$invalid){
                        invalidFields.push(name);
                    }
                }
            },
            IsInvalid(key){
                return this.$v.WireTransfer[key].$invalid;
            },
            UserAgency(agency){
                return this.AllAgencyAccess ||(this.IsAgencyArray? this.userDataMapping.cdc839a5c1db4c43bb5b0e180cf0270e.findIndex(d=>d==agency)>-1:false);
            },
            AssignDebitCompany() {
                let comapanySelected = this.WireTransfer.CompanyName;
                if (comapanySelected) {
                    let companyName=this.CompanyNameOptions.findIndex(val=>{
                       return val.value.toLowerCase()==comapanySelected;
                   });
                   let companyLabel=this.CompanyNameOptions[companyName].label.toLowerCase();
                   //window.console.log(`company label is ${companyLabel}`);
                   let debitCompanyMapping=this.CompanyDebitCompanyMapping.findIndex(val=>{
                       return val.label.toLowerCase()==companyLabel;
                   });
                   if(debitCompanyMapping>-1){
                    this.$set(this.WireTransfer, "DebitCompany", this.CompanyDebitCompanyMapping[debitCompanyMapping].value);
                   }
                } else {
                    this.$set(this.WireTransfer, "DebitCompany", null);
                }
            },
            ResetVendorConatctInfo() {
                this.$set(this.WireTransfer, "VendorName", null);
                this.$set(this.WireTransfer, "VendorEmail", null);
                this.$set(this.WireTransfer, "VendorPhoneNumber", null);
                this.$set(this.WireTransfer, "VendorRole", null);
                this.$set(this.WireTransfer, "VendorContactInfoObtainedFrom", null);

                this.$set(this.WireTransfer, "VendorInfoObtainedWebsite", null);
                this.$set(this.WireTransfer, "OMGEmpolyeeName", null);
                this.$set(this.WireTransfer, "OMGEmpolyeeRole", null);
            },
            SetDirty(property) {
                this.$v.WireTransfer[property].$touch();
            },
            ResetDirty(property) {
                this.$v.WireTransfer[property].$reset();
            },
            DownloadDropzoneFile() {
                //window.console.log(`Download ${JSON.stringify(file)}`);
            },
            GetTransacationNumber() {
                this.$socket.invoke('GetTransactionNumber', this.UniqueTabId).then((res) => {
                    //window.console.log(`get number ${res}`);
                    this.$set(this.WireTransfer, "TransactionNo", res);
                })
            },
            DelteTransacationNumber() {
                this.$socket.invoke('DeleteTransactionNumber', this.UniqueTabId).then(() => {
                    //     window.console.log(`get number ${res}`);
                    //    this.$set(this.WireTransfer,"TransactionNo", res);
                })
            },
            ManuallyUploadFiles(files) {
                if (files != null && files.length > 0) {
                    files.forEach((fileUploaded) => {
                        let file = {
                            size: fileUploaded.Length,
                            name: fileUploaded.Name,
                            type: fileUploaded.Extension,
                        };
                        var url = fileUploaded.FullName;
                        this.$refs.myVueDropzone.manuallyAddFile(file, url);
                    });
                }
            },
            LoadWatchers() {
                let watchers = this.WireTransfer.WireTransferRequestWatchers;
                // window.console.log(`watchers from server ${JSON.stringify(watchers)}`);
                // window.console.log(`watchers list ${JSON.stringify(this.Watchers)}`);

                if (watchers && watchers.length > 0) {
                    //this.WireTransferRequestWatchers = [];
                    // let existing=[];
                    for (var i = 0; i < watchers.length; i++) {
                        let watcher = watchers[i];
                        let exists = this.Watchers.findIndex(d => {
                            return d.UserName == watcher.UserName;
                        });
                        let existsInList = this.WireTransferRequestWatchers.findIndex(d => {
                            return d.UserName == watcher.UserName;
                        });

                        if (exists > -1 && existsInList == -1) {
                            this.WireTransferRequestWatchers.push(Object.assign({}, this.Watchers[exists]));
                        }
                    }
                    // this.$set(this.WireTransfer,"WireTransferRequestWatchers", existing);

                }

            },
            async LoadFormData() {
                this.$store
                    .dispatch("wiretransfer/GetFormData", parseInt(this.id))
                    .then((res) => {
                        //window.console.log(`test requested date ${JSON.stringify(res.data)}`);
                        Object.assign(this.WireTransfer, res.data);
                        let newRequestedTransferDate=moment(moment.utc(res.data.RequestedTransferDate).local().format("MM/DD/YYYY")).isBefore(moment())?new Date():new Date(`"${moment(moment.utc(res.data.RequestedTransferDate)).get('month')+2},${moment(moment.utc(res.data.RequestedTransferDate)).get('date')},${moment(moment.utc(res.data.RequestedTransferDate)).get('year')}"`);
                        this.$set(this.WireTransfer, "RequestedTransferDate", newRequestedTransferDate);
                        this.RequestedTransferDate = newRequestedTransferDate;
                        //window.console.log('newRequestedTransferDate' + newRequestedTransferDate);
                        if (res.data.Files != null && res.data.Files.length > 0) {
                            this.ManuallyUploadFiles(res.data.Files);
                            this.$set(this.WireTransfer, "Files", this.GetFilesObject());
                        }
                        this.$set(this.WireTransfer, "ResubmitNotes", null);
                        this.LoadWatchers();
                        this.$watch('WireTransfer', () => {
                            this.dataChanged = true;
                        }, {
                            deep: true
                        })
                        this.$watch('RequestedTransferDate', () => {
                            this.dataChanged = true;
                        })
                        this.$watch('WireTransferRequestWatchers', () => {
                            this.dataChanged = true;
                        }, {
                            deep: true
                        })
                        this.formatCurrency();
                        this.isDataLoaded = true;
                    })
                    .catch((ex) => {
                        this.isDataLoaded = true;

                        if (ex.response) {
                            var commenmsg = "";
                            if (ex.response.status == 500) {
                                commenmsg = "Please check your inputs or try again later";
                                store.dispatch("progress/setErrorMessage", `${commenmsg}`);
                            } else if (ex.response.status == 400) {
                                for (var i = 0; i < ex.response.data.length; i++) {
                                    if (ex.response.data[i].ErrorMessage != 'undefined') {
                                        commenmsg += ex.response.data[i].ErrorMessage;
                                    }
                                }
                                store.dispatch("progress/setErrorMessage", `${commenmsg}`);
                            } else if (ex.response && ex.response.status == 403) {
                                this.$router.push({
                                    name: "error",
                                    params: {
                                        message: "UnAuthorized"
                                    },
                                });
                            } else {
                                store.dispatch("progress/setErrorMessage", `${ex.response.data}`);
                            }
                        } else {
                            store.dispatch(
                                "progress/setErrorMessage",
                                `Error while loading data. ${ex}`
                            );
                        }
                    });
            },
            async LoadDraftFormData() {
                this.$store
                    .dispatch("wiretransfer/GetDraftFormData", parseInt(this.id))
                    .then((res) => {
                        this.WireTransfer = Object.assign(this.WireTransfer, res.data);
                        this.$set(this.WireTransfer, "RequestedTransferDate", new Date(res.data.RequestedTransferDate).toISOString());
                        if (res.data.Files != null && res.data.Files.length > 0) {
                            this.ManuallyUploadFiles(res.data.Files);
                            this.$set(this.WireTransfer, "Files", this.GetFilesObject());
                        }
                        this.LoadWatchers();
                        this.isDataLoaded = true;

                    })
                    .catch((ex) => {
                        this.isDataLoaded = true;

                        if (ex.response) {
                            var commenmsg = "";
                            if (ex.response.status == 500) {
                                commenmsg = "Please check your inputs or try again later";
                                store.dispatch("progress/setErrorMessage", `${commenmsg}`);
                            } else if (ex.response.status == 400) {
                                for (var i = 0; i < ex.response.data.length; i++) {
                                    if (ex.response.data[i].ErrorMessage != 'undefined') {
                                        commenmsg += ex.response.data[i].ErrorMessage;
                                    }
                                }
                                store.dispatch("progress/setErrorMessage", `${commenmsg}`);
                            } else {
                                store.dispatch("progress/setErrorMessage", `${ex.response.data}`);
                            }
                        } else {
                            store.dispatch(
                                "progress/setErrorMessage",
                                `Error while loading data. ${ex}`
                            );
                        }
                    });
            },
            async LoadTemplateFormData() {
                this.$store
                    .dispatch("wiretransfer/GetTemplate", parseInt(this.id))
                    .then((res) => {
                        this.WireTransfer = Object.assign(this.WireTransfer, res.data);
                        if (res.data.Files != null && res.data.Files.length > 0) {
                            this.ManuallyUploadFiles(res.data.Files);
                            this.$set(this.WireTransfer, "Files", this.GetFilesObject());
                        }

                    })
                    .catch((ex) => {
                        this.isDataLoaded = true;

                        if (ex.response) {
                            var commenmsg = "";
                            if (ex.response.status == 500) {
                                commenmsg = "Please check your inputs or try again later";
                                store.dispatch("progress/setErrorMessage", `${commenmsg}`);
                            } else if (ex.response.status == 400) {
                                for (var i = 0; i < ex.response.data.length; i++) {
                                    if (ex.response.data[i].ErrorMessage != 'undefined') {
                                        commenmsg += ex.response.data[i].ErrorMessage;
                                    }
                                }
                                store.dispatch("progress/setErrorMessage", `${commenmsg}`);
                            } else {
                                store.dispatch("progress/setErrorMessage", `${ex.response.data}`);
                            }
                        } else {
                            store.dispatch(
                                "progress/setErrorMessage",
                                `Error while loading data. ${ex}`
                            );
                        }
                    });
            },
            async LoadWatcherList() {
                this.Watchers = [];
                this.WireTransferRequestWatchers.splice(0, this.WireTransferRequestWatchers.length)
                this.$store
                    .dispatch("wiretransfer/GetUsers", {
                        agencyName: '',
                        name: 'Watchers'
                    })
                    .then((res) => {
                        if (res.data.length > 0) {
                            res.data.forEach((val) => {
                                let name = this.formatEmailToUserName(val);
                                this.Watchers.push({
                                    id: val,
                                    name: name,
                                    UserName: val
                                });
                            });
                            if (this.IsEdit == true) {
                                this.LoadWatchers();
                                //this.isDataLoaded = true;
                            }
                        } else {
                            this.Watchers = [];
                            //this.isDataLoaded = true;

                        }
                    }).catch((ex) => {
                        window.console.error(ex);
                    });
            },
            // async LoadApproverList() {
            //     this.AuthorizedOptions = [];
            //     this.$store
            //         .dispatch("wiretransfer/GetUsers", {
            //             agencyName: this.WireTransfer.CompanyName,
            //             name: 'Approvers'
            //         })
            //         .then((res) => {
            //             if (res.data.length > 0) {
            //                 res.data.forEach((val) => {
            //                     let name = this.formatEmailToUserName(val);
            //                     this.AuthorizedOptions.push({
            //                         label: name,
            //                         value: val
            //                     });
            //                 });
            //             } else {
            //                 // this.AuthorizedOptions = [];
            //             }
            //         }).catch((ex) => {
            //             window.console.error(ex);
            //         });
            // },
            formatEmailToUserName(email) {
                if (email.indexOf("@") > -1) {
                    let name = email.substring(0, email.indexOf("@"));
                    let values = name.split(".");
                    name = "";
                    for (var i = 0; i < values.length; i++) {
                        let val = values[i];
                        name += " " + val.substring(0, 1).toUpperCase() + val.substring(1, val.length);
                    }
                    return name.trim();
                } else {
                    return email;
                }
            },
            ResetAnotherRequest() {
                this.$refs.myVueDropzone.dropzone.removeAllFiles();
                this.$set(this.WireTransfer, "DebitCompany", null);
                this.$set(this.WireTransfer, "DebitAccountNumber", null);
                this.$set(this.WireTransfer, "Beneficiary", null);
                this.$set(this.WireTransfer, "BeneficiaryStreetAddress", null);
                this.$set(this.WireTransfer, "BeneficiaryCountry", "US");
                this.$set(this.WireTransfer, "BeneficiaryCity", null);
                this.$set(this.WireTransfer, "BeneficiaryZipCode", null);
                this.$set(this.WireTransfer, "BeneficiaryReference", null);
                this.$set(this.WireTransfer, "CreditAccountName", null);
                this.$set(this.WireTransfer, "CreditAccountNumber", null);
                this.$set(this.WireTransfer, "Currency", "b19b013b-b07f-4d2d-9692-f3cb154bae0a");
                this.$set(this.WireTransfer, "BankName", null);
                this.$set(this.WireTransfer, "BankCountry", null);
                this.$set(this.WireTransfer, "BankBICType", null);
                this.$set(this.WireTransfer, "BankBIC", null);
                this.$set(this.WireTransfer, "InvoiceCurrency", null);
                this.$set(this.WireTransfer, "TotalInvoiceAmount", null);
                this.$set(this.WireTransfer, "RequestedTransferDate", new Date());
                this.RequestedTransferDate = new Date();
                this.$set(this.WireTransfer, "PayingBankCharges", "Remitter");
                this.$set(this.WireTransfer, "ReceivingBankCharges", "Beneficiary");
                this.$set(this.WireTransfer, "RemittanceAdvice", "No");
                this.$set(this.WireTransfer, "Paythrough", "No");
                this.$set(this.WireTransfer, "PaythroughBankName", null);
                this.$set(this.WireTransfer, "PaythroughBankCountry", null);
                this.$set(this.WireTransfer, "PaythroughBICType", null);
                this.$set(this.WireTransfer, "PaythroughBIC", null);
                this.$set(this.WireTransfer, "AuthorizedBy", null);

                this.$set(this.WireTransfer, "VendorName", null);
                this.$set(this.WireTransfer, "VendorEmail", null);
                this.$set(this.WireTransfer, "VendorPhoneNumber", null);
                this.$set(this.WireTransfer, "VendorRole", null);
                this.$set(this.WireTransfer, "VendorContactInfoObtainedFrom", null);

                this.$set(this.WireTransfer, "VendorInfoObtainedWebsite", null);
                this.$set(this.WireTransfer, "OMGEmpolyeeName", null);
                this.$set(this.WireTransfer, "OMGEmpolyeeRole", null);

                let watchersCount = this.WireTransferRequestWatchers.length;
                for (var i = 0; i < watchersCount; i++) {
                    this.$delete(this.WireTransferRequestWatchers, i);
                }
            },
            LoadDropdownValues() {
                this.LoadCurrencies();
                //this.LoadCountries();
                this.LoadAgency();
            },
            async LoadAgency() {
                service
                    .getAllAgencyDropdownValues("")
                    .then((res) => {
                        this.CompanyNameOptions = res.data.sort((a, b) => {
                            let fa = a.label.toLowerCase(),
                                fb = b.label.toLowerCase();
                            if (fa < fb) {
                                return -1;
                            }
                            if (fa > fb) {
                                return 1;
                            }
                            return 0;
                        });
                    })
                    .catch((err) => {
                        alert(err);
                    });
            },
            async LoadCurrencies() {
                service
                    .getOriginalEntityRecords("Currencies")
                    .then((res) => {
                        if (res.data && res.data.length > 0) {
                            this.CurrencyOptions = res.data.sort((a, b) =>
                                a.label < b.label ? -1 : a.label > b.label ? 1 : 0
                            );
                            // let indexUSA = this.CurrencyOptions.findIndex(
                            //     (i) => i.label == "USD  - US Dollar "
                            // );
                            // let indexCanada = this.CurrencyOptions.findIndex(
                            //     (i) => i.label == "CAD  - Canadian Dollar "
                            // );
                            // if (indexCanada > -1) {
                            //     this.CurrencyOptions.unshift(
                            //         this.CurrencyOptions.splice(indexCanada, 1)[0]
                            //     );
                            // }
                            // if (indexUSA > -1) {
                            //     this.CurrencyOptions.unshift(
                            //         this.CurrencyOptions.splice(indexUSA, 1)[0]
                            //     );

                            // }
                            this.CurrencyOptions = this.CurrencyOptions.map(curr => {
                                return {
                                    label: curr.label.toUpperCase(),
                                    value: curr.value
                                };
                            });
                        }
                    })
                    .catch((err) => {
                        alert(err);
                    });
            },
            async LoadCountries() {
                service
                    .getEntityRecords("Markets")
                    .then((res) => {
                        if (res.data && res.data.length > 0) {
                            this.CountryOptions = res.data.sort((a, b) =>
                                a.label < b.label ? -1 : a.label > b.label ? 1 : 0
                            );
                            let indexUSA = this.CountryOptions.findIndex(
                                (i) => i.label == "USA - United States"
                            );
                            let indexCanada = this.CountryOptions.findIndex(
                                (i) => i.label == "CAN - Canada"
                            );
                            if (indexCanada > -1)
                                this.CountryOptions.unshift(
                                    this.CountryOptions.splice(indexCanada, 1)[0]
                                );
                            if (indexUSA > -1)
                                this.CountryOptions.unshift(this.CountryOptions.splice(indexUSA, 1)[0]);
                        }
                    })
                    .catch((err) => {
                        alert(err);
                    })
            },
            PrepareData(draft) {
                let wireInfo = Object.assign({}, this.WireTransfer);
                if (draft == true) {
                    wireInfo.RequestedTransferDate = moment(this.WireTransfer.RequestedTransferDate).isValid() ? moment(new Date(this.WireTransfer.RequestedTransferDate)).format(moment.HTML5_FMT.DATE) : moment().format(moment.HTML5_FMT.DATE);
                }
                if (draft != true) {
                    wireInfo.RequestedTransferDate = moment(this.RequestedTransferDate).isValid() ? moment.utc(this.RequestedTransferDate).format() : moment.utc(new Date()).format();

                } else {
                    wireInfo.TotalInvoiceAmount = wireInfo.TotalInvoiceAmount != null && !isNaN(wireInfo.TotalInvoiceAmount) ? wireInfo.TotalInvoiceAmount : 0;
                }
                this.$set(this.WireTransfer, "WireTransferRequestWatchers", this.WireTransferRequestWatchers);
                wireInfo.WireTransferRequestWatchers = this.PrepareWatchersObject();
                wireInfo.Files = [];
                this.WireTransfer.Files.forEach(s => {
                    wireInfo.Files.push({
                        Name: s.name
                    });
                });
                return wireInfo;
            },
            PrepareWatchersObject() {
                let watchers = [];
                if (this.WireTransfer == null || this.WireTransfer.WireTransferRequestWatchers == null || this.WireTransfer.WireTransferRequestWatchers.length <= 0) {
                    return watchers;
                } else {
                    this.WireTransfer.WireTransferRequestWatchers.forEach(d => {
                        watchers.push({
                            ID: 0,
                            UserName: d.UserName,
                            MediaWireTransferRequestID: 0
                        });
                    });
                }
                return watchers;
            },
            UploadedToDropzone() {

                this.$set(this.WireTransfer, "Files", this.GetFilesObject());
            },
            RemovedFromDropzone() {
                this.$set(this.WireTransfer, "Files", this.GetFilesObject());
            },
            GetFilesObject() {
                let files = [];
                if (this.$refs.myVueDropzone.dropzone.files && this.$refs.myVueDropzone.dropzone.files.length > 0) {
                    let filesExists = this.$refs.myVueDropzone.dropzone.files;
                    for (let i = 0; i < filesExists.length; i++) {
                        files.push({
                            name: filesExists[i].name
                        });
                    }
                    return files;
                } else {
                    return files;
                }
            },
            Cancel() {
                this.$router.push("/WireTransfers");
            },
            template: function () {
                return `<div class="dz-preview dz-file-preview" style='background-color:#ffffff !important;display:flex;'>

                    <div class="dz-details" style='margin-right:30px !important;'>
                        <div class="dz-size" style="display:none;" ><span data-dz-size></span></div>
                        <div class="FileforNewClientxls-Copy" style="margin-left:20px;width:270px;cursor:default !important;" ><span style='word-break:break-all !important;font-size:14px !important;font-weight:400;font-stretch:normal;font-style: normal;line-height: 0.71;color: #00a1d2 !important; font-family:"Open Sans" !important;' data-dz-name @click="DownloadDropzoneFile()"></span></div>
                    </div>
                    <a>
                     <img data-dz-remove=""  style='' src='/icons/fileremove.svg'  @click="DeleteServerFile()" ></img>
                    </a>
                </div>`;
            },
            SaveAsDraft() {
                let data = this.PrepareData(true);
                // window.console.log(`TotalInvoiceAmount before ${data.TotalInvoiceAmount}`);
                if (data.TotalInvoiceAmount == null) {
                    data.TotalInvoiceAmount = 0;
                    // window.console.log(`data corrected ${data.TotalInvoiceAmount}`);
                }
                let formData = new FormData();
                let files = this.$refs.myVueDropzone.dropzone.files;
                for (var i = 0; i < files.length; i++) {
                    let file = files[i];

                    formData.append('files[' + i + ']', file);
                }
                formData.append('request', JSON.stringify(data));
                formData.append('totalFiles', JSON.stringify(data.Files.map(val => {
                    return val.Name
                })));
                formData.append('draftRequestId', this.isDraft == true ? parseInt(this.id) : 0);
                this.$store
                    .dispatch("wiretransfer/SaveAsDraft", formData)
                    .then(() => {
                        this.$router.push("/WireTransfers");
                        store.dispatch(
                            "progress/setSuccessMessage",
                            "Your wire transfer request has been saved as draft!"
                        );
                    })
                    .catch((ex) => {
                        window.console.error(JSON.stringify(ex));
                        if (ex.response) {
                            var commenmsg = "";
                            if (ex.response.status == 500) {
                                commenmsg = "Please check your inputs or try again later";
                                store.dispatch("progress/setErrorMessage", `${commenmsg}`);
                            } else if (ex.response.status == 400) {
                                for (var i = 0; i < ex.response.data.length; i++) {
                                    if (ex.response.data[i].ErrorMessage != "undefined") {
                                        commenmsg += ex.response.data[i].ErrorMessage;
                                    }
                                }
                                store.dispatch("progress/setErrorMessage", `${commenmsg}`);
                            } else {
                                store.dispatch("progress/setErrorMessage", `${ex.response.data}`);
                            }
                        } else {
                            store.dispatch(
                                "progress/setErrorMessage",
                                `Error while saving wire transfer request as draft. ${ex}`
                            );
                        }
                    });
            },
            SaveTemplate() {
                this.showTemplateModel=true;
            },
            SaveAllTemplateData()
            {
                let data = this.PrepareData(true);
                if (data.TotalInvoiceAmount == null) {
                    data.TotalInvoiceAmount = 0;
                }
                let formData = new FormData();
                let files = this.$refs.myVueDropzone.dropzone.files;
                for (var i = 0; i < files.length; i++) {
                    let file = files[i];
                    formData.append('files[' + i + ']', file);
                }
                formData.append('request', JSON.stringify(data));
                formData.append('templateName', JSON.stringify(this.TemplateName));
                formData.append('templateDescription', JSON.stringify(this.Description));
                formData.append('totalFiles', JSON.stringify(data.Files.map(val => {
                    return val.Name
                })));
                formData.append('templateRequestId', this.isTemplate == true ? parseInt(this.id) : 0);
                console.log("Data from UI :",formData)
                this.$store
                    .dispatch("wiretransfer/SaveAsTemplate", formData)
                    .then(() => {
                        this.$router.push("/WireTransfers");
                        store.dispatch(
                            "progress/setSuccessMessage",
                            "Your wire transfer request has been saved as Template!"
                        );
                    })
                    .catch((ex) => {
                        window.console.error(JSON.stringify(ex));
                        if (ex.response) {
                            var commenmsg = "";
                            if (ex.response.status == 500) {
                                commenmsg = "Please check your inputs or try again later";
                                store.dispatch("progress/setErrorMessage", `${commenmsg}`);
                            } else if (ex.response.status == 400) {
                                for (var i = 0; i < ex.response.data.length; i++) {
                                    if (ex.response.data[i].ErrorMessage != "undefined") {
                                        commenmsg += ex.response.data[i].ErrorMessage;
                                    }
                                }
                                store.dispatch("progress/setErrorMessage", `${commenmsg}`);
                            } else {
                                store.dispatch("progress/setErrorMessage", `${ex.response.data}`);
                            }
                        } else {
                            store.dispatch(
                                "progress/setErrorMessage",
                                `Error while saving wire transfer request as draft. ${ex}`
                            );
                        }
                    });
            },
            Submit() {
                let confirms = confirm("I have verified all vendor & banking details and confirm the accuracy of the information listed herein.");

                if (confirms == true) {

                    let debtAccountNumberExists = this.DebitCompanyAndAccountNumberMapping.findIndex(val => val.label == this.WireTransfer.DebitCompany);
                    if (debtAccountNumberExists > -1) {
                        this.$set(this.WireTransfer, 'DebitAccountNumber', this.DebitCompanyAndAccountNumberMapping[debtAccountNumberExists].value);
                    }
                    let data = this.PrepareData();
                    if (this.IsEdit == true && this.isDraft == true && this.isTemplate==true) {
                        data.DraftRequestID = parseInt(this.id);
                    }
                    let formData = new FormData();
                    let files = this.$refs.myVueDropzone.dropzone.files;
                    for (var i = 0; i < files.length; i++) {
                        let file = files[i];

                        formData.append('files[' + i + ']', file);
                    }
                    formData.append('request', JSON.stringify(data));
                    this.$store
                        .dispatch("wiretransfer/AddRequestWithFiles", formData)
                        .then(() => {
                            // window.console.log(`another request is ${this.CreateAnotherRequest}`)
                            if (this.CreateAnotherRequest == true || this.CreateAnotherRequest == "true") {
                                // window.console.log(`another request inside is ${this.CreateAnotherRequest}`)
                                this.ResetAnotherRequest();
                                this.CreateAnotherRequest = null;
                            } else {
                                this.$router.push("/WireTransfers");
                            }
                            store.dispatch(
                                "progress/setSuccessMessage",
                                "Your wire transfer request has been submitted!"
                            );
                        })
                        .catch((ex) => {
                            window.console.error(JSON.stringify(ex));
                            if (ex.response) {
                                var commenmsg = "";
                                if (ex.response.status == 500) {
                                    commenmsg = "Please check your inputs or try again later";
                                    store.dispatch("progress/setErrorMessage", `${commenmsg}`);
                                } else if (ex.response.status == 400) {
                                    for (var i = 0; i < ex.response.data.length; i++) {
                                        if (ex.response.data[i].ErrorMessage != "undefined") {
                                            commenmsg += ex.response.data[i].ErrorMessage;
                                        }
                                    }
                                    store.dispatch("progress/setErrorMessage", `${commenmsg}`);
                                } else {
                                    store.dispatch("progress/setErrorMessage", `${ex.response.data}`);
                                }
                            } else {
                                store.dispatch(
                                    "progress/setErrorMessage",
                                    `Error while creating wire transfer request. ${ex}`
                                );
                            }
                        });
                }
            },
            Resubmit() {

                let data = this.PrepareData();
                let formData = new FormData();
                let files = this.$refs.myVueDropzone.dropzone.getAcceptedFiles();
                // window.console.log(files.length);
                for (var i = 0; i < files.length; i++) {
                    let file = files[i];

                    formData.append('files[' + i + ']', file);
                }
                formData.append('request', JSON.stringify(data));
                this.$store
                    .dispatch("wiretransfer/UpdateRequestWithFiles", {
                        id: parseInt(this.id),
                        data: formData
                    })
                    .then(() => {
                        this.$router.push("/WireTransfers");
                        store.dispatch(
                            "progress/setSuccessMessage",
                            "Your wire transfer request has been updated!"
                        );
                    })
                    .catch((ex) => {
                        window.console.error(JSON.stringify(ex));
                        if (ex.response) {
                            var commenmsg = "";
                            if (ex.response.status == 500) {
                                commenmsg = "Please check your inputs or try again later";
                                store.dispatch("progress/setErrorMessage", `${commenmsg}`);
                            } else if (ex.response.status == 400) {
                                for (var i = 0; i < ex.response.data.length; i++) {
                                    if (ex.response.data[i].ErrorMessage != "undefined") {
                                        commenmsg += ex.response.data[i].ErrorMessage;
                                    }
                                }
                                store.dispatch("progress/setErrorMessage", `${commenmsg}`);
                            } else {
                                store.dispatch("progress/setErrorMessage", `${ex.response.data}`);
                            }
                        } else {
                            store.dispatch(
                                "progress/setErrorMessage",
                                `Error while updating wire transfer request. ${ex}`
                            );
                        }
                    });
            }
        },
        computed: {
              ...mapGetters({
                loggedInUser: "emailAddress"
            }),
            canResubmitRequest() {
                return this.WireTransfer.Requestor == this.loggedInUser && (this.WireTransfer.Status == wiretransferStatus.Returned || this.WireTransfer.Status == wiretransferStatus.Rejected || this.WireTransfer.Status == wiretransferStatus.Requested);
            },
            isSubmitOrSaveTemplate() {
            return !this.IsEdit ||(this.IsEdit&&(this.isDraft||this.isTemplate))
            },
            isSubmitDisabled() {
                return this.TemplateName.trim() !== '' && this.Description.trim() !== '';
            },
            canApproveLevel1WireTransferRequest() {
                return this.$store.getters.canApproveLevel1WireTransferRequest;
            },
            userDataMapping() {
                return this.$store.getters.dataMappings;
            },
            AllAgencyAccess(){
                return this.userDataMapping!=null && this.userDataMapping.cdc839a5c1db4c43bb5b0e180cf0270e=="*";
            },
            IsAgencyArray(){
                return this.userDataMapping!=null &&!this.AllAgencyAccess&&Array.isArray(this.userDataMapping.cdc839a5c1db4c43bb5b0e180cf0270e);
            },
            IsVendorInfoRequired() {
                return this.WireTransfer.NewVendor == "Yes";
            },
            DebitAccountNumberOptions() {
                return this.DebitCompanyAndAccountNumberMapping.map(val => {
                    return {
                        label: val.value,
                        value: val.value
                    };
                })
            },
            VendorCodelabel() {
                return this.WireTransfer.PaymentType == 'Client Refund' ? 'Client Code' : 'Vendor code';
            },
            hasPayrollPermission() {
                return this.$store.getters.canSubmitPayrollWireTransferRequest;
            },
            MediaList() {
                let list = [];
                list.push({
                    label: "Net",
                    value: NETMedia
                });
                list.push({
                    label: "Spot",
                    value: SPOTMedia
                });
                list.push({
                    label: "Print",
                    value: PRINTMedia
                });
                return list;
            },
            VendorCodePlaceholder() {
                return this.WireTransfer.VendorSystem ? `Select  ${this.WireTransfer.VendorSystem} Vendor Code` : 'Select Vendor Code';
            },
            IsVendorCodeVisible(){
                return this.WireTransfer.PaymentType != "Payroll";
            },
            IsDraftVisible() {
                return false;
            },
            IsEdit() {
                return this.id && !isNaN(this.id) && parseInt(this.id) > 0 ? true : false;
            },
            DebitCompanyOptionsMapping() {
                if (!this.WireTransfer.CompanyName)
                    return [];

                var companyName = this.CompanyNameOptions.filter(x => x.value == this.WireTransfer.CompanyName);

                var result = [];
                this.CompanyDebitCompanyMapping.map(function (item) {
                    if (item.label == companyName[0].label){
                        result.push({
                            label: item.value,
                            value: item.value
                        })
                    }
                });
                return result;
            }
        },
    }
    </script>

    <style scoped>
    .modal-content {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 50%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 0.3rem;
    outline: 0;
    }
  .modal-dialog-centered {
  max-width: 200px;
    }
    .transaction-label {
        font-size: 10px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.7;
        letter-spacing: normal;
        color: #a2a9ad;
        text-transform: uppercase;
        margin-bottom: 5px;
    }

    .transaction-id {
        text-align: center;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #4d5161;

    }

    .transaction-number {
        position: absolute;
        top: 5px;
        box-shadow: inset -1px 0 0 0 #eff3f5;
        background-color: #f7f7f9;
        right: 35px;
        height: 65px;
        padding: 9px 35px 12px;
    }

    .card.direct-component {
        margin-left: 30px;
        margin-right: 30px;
        margin-top: 35px;
        border: solid 1px rgba(239, 242, 244, 0.2);
        border-radius: 8px;
        box-shadow: 0 1px 3px 0 rgba(9, 30, 66, 0.12);
        padding: 0px 50px;
        padding-bottom: 50px;
    }

    .card.direct-component>.card-main-title {
        font-family: "Open Sans";
        font-size: 20px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #4d5161;
        margin-bottom: 30px;
        margin-top: 20px;
    }

    .section-title {
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #4d5161;
    }

    .section-desc {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.58;
        letter-spacing: normal;
        color: #4d5161;
    }

    .section-content {
        margin-top: 30px;
    }

    .section-row .floating-label-input {
        margin-top: 19px;
    }

    .section-row .stacked-rows>.floating-label-input:first-child {
        margin-top: 25px;

    }

    /* .section-row .floating-label {
        padding-left: 25px;
    } */
    .section-devider {
        margin-top: 24px;
        opacity: 0.2;
        border: solid 1px #979797;
        margin-bottom: 30px;
    }

    .section-content>.section-row:not(:first-child) {
        margin-top: 20px;
    }

    .section-content>.section-row.no-input {
        margin-top: 40px;
    }

    .customdropzone {
        min-height: 127px !important;
        border-radius: 8px !important;
        border: dashed 2px #e9eef0 !important;
        background-color: #ffffff !important;
        cursor: pointer !important;
    }

    .Drag-and-drop-file-h {
        /* padding-top: 4px;
        padding-left: 20px; */
        margin-left: 60px !important;
        margin-top: 50px !important;
        /*width: 387px !important;
        height: 120px !important; */
        font-family: "Open Sans" !important;
        font-size: 14px !important;
        font-weight: normal !important;
        font-stretch: normal !important;
        font-style: normal !important;
        line-height: 0.71 !important;
        letter-spacing: normal !important;
        /* position: absolute !important; */

        /* margin-top: 14px !important; */
        /* left: 50%;
        top: 50%;
        transform: translate(-50%, -50%); */
        /* color: #a2a9ad !important; */
    }

    .customdropzone.dz-started .Drag-and-drop-file-h {
        margin-top: 14px !important;
    }

    .fieldborder {
        padding-bottom: 15px !important;
    }

    .horizontal-devider {
        height: 38px;
        border-right: solid 1px #eaeaea;
    }

    .stacked-rows>*:nth-child(2) {
        margin-top: 23px;
    }

    .notes-fixed-height {
        height: 127px;
    }

    .draganddropspan {
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.71;
        letter-spacing: normal;
        color: #00a1d2;
    }

    fieldset.form-group.custom-radio>legend {
        padding-left: 12px;
    }

    fieldset.form-group:not(.custom-radio) {
        margin-top: 0px;
    }

    fieldset.form-group>div {
        padding-top: 3px !important;
    }

    .floating-label-input .floating-label {
        top: -11.5px !important;
    }

    .floating-label-input .floating-label {
        top: -11.5px !important;
    }

    fieldset.form-group.custom-radio {
        margin-top: 0px;
    }

    fieldset.form-group>div>.col-form-label {
        padding-top: 0px !important;
        padding-left: 12px;
    }

    fieldset.form-group.custom-radio>div>.col-form-label {
        padding-left: 0px;
    }

    fieldset.form-group>div>input:nth-child(2) {
        margin-top: 3px !important;
    }

    .redirect-home {
        font-size: 12px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.75;
        letter-spacing: normal;
        color: #00a1d2;
        margin-left: 12px !important;
    }

    .redirect-home img {
        vertical-align: baseline;
    }
    </style>

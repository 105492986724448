<template>
   <wire-transfer-info :id="id" :isDraft="isDraft"  :isTemplate="isTemplate"></wire-transfer-info>
</template>

<script>
import WireTransferInfo from "../components/WireTransfer/WireTransferInfo";

export default {
  components: {
   WireTransferInfo:WireTransferInfo,
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
    isDraft: {
      type: Boolean,
      required: false,
    },
    isTemplate: {
      type: Boolean,
      required: false,
    },
  },
  mounted() {
    if (!this.CanCreateWireTransferRequest) {
      this.$router.push({
        name: "unauthorized",
        params: { message: "UnAuthorized" },
      });
    } 
  },
  data() {
    return {

    };
  },
  computed: {

    CanCreateWireTransferRequest() {
      return this.$store.getters.canCreateWireTransferRequest;
    },
    CanAccessList() {
      return this.$store.getters.canAccessList;
    },
  },
};
</script>

<style scoped>
.stepper-box,
.stepper-box .top .steps-wrapper .step .circle {
  background-color: transparent !important;
}

.content-holder {
  background-color: #ffffff !important;
}
.row.page-title {
  height: 55px;
  background-color: #eef3f5 !important;
}
.row.page-title > label {
  padding: 14px 0px 14px 55px !important;
  font-family: "Open Sans";
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.81px;
  color: #4d5161;
  text-transform: none !important;
}

.progress-bar[data-v-ef603d5c]:not(.hide-number):before {
  width: 30px !important;
  height: 30px !important;
  padding-top: 6px !important;
}
</style>
